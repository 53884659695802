import React from "react";

import "./index.scss";
import { RightAnimation } from "../../../page-animation";
import { TradeButton } from "./trade-button";

interface IProps {
  balance: number;
  maxBalance: number;
  currentTradeSessionStartAt: number | null;
  onClaim: () => void;
  remainingTime: string | null;
  canClaim: boolean;
}

export const ActionButton: React.FC<IProps> = ({
  balance,
  maxBalance,
  currentTradeSessionStartAt,
  onClaim,
  remainingTime,
  canClaim
}) => {
  
  const getButton = () => {
    if (canClaim) {
      return (
        <div onClick={onClaim} className="wai-action-text-timer-claim ab">
          Claim
        </div>
      );
    }
     else if (currentTradeSessionStartAt === null) {
      return (
        <TradeButton onClaim={onClaim} />
      );
    } 
    else {
      return remainingTime ? <div className="wai-action-text-timer-claim ab">
        <div style={{width: '120px', height: '29.9px', display: 'flex', justifyContent: 'center' }}>{remainingTime}</div>
      </div> : null;
    }
  };

  return (
    <div className="wai-action-button-root">
       <RightAnimation>
      <div className="wai-action-wrapper">
        <p className="white-color wai-action-text">
          Per session: {balance.toFixed(2)}/{maxBalance.toFixed(2)} WAI
        </p>
        <div className="action-btn-wrapper">
          {getButton()}
        </div>
      </div>
      </RightAnimation>
    </div>
  );
};
