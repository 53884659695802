import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { EAPITagType } from "./enums";

export const REACT_APP_API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
export const JWT_TOKEN_KEY = "jwtToken";

export const baseApi = createApi({
  reducerPath: "api",
  baseQuery: fetchBaseQuery({
    baseUrl: REACT_APP_API_ENDPOINT || "http://localhost:1337",
    credentials: "include",
    prepareHeaders(headers, { endpoint }) {
      // Do not set the authorization header for the auth endpoint
      if (endpoint === "auth" || endpoint === "register") {
        return headers;
      }
      // Get the JWT token from local storage
      const token = localStorage.getItem(JWT_TOKEN_KEY);

      // Set the authorization header with the bearer token
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }

      return headers;
    },
  }),
  tagTypes: [
    EAPITagType.USERS,
    EAPITagType.MATES_LIST,
    EAPITagType.MATES_GET_REWARDS,
    EAPITagType.UPDATE_USERS,
    EAPITagType.LEADERBOARD,
    EAPITagType.VALIDATE_USER_IN_GROUP,
    EAPITagType.TASKS,
    EAPITagType.STATS,
    EAPITagType.STAKE_CREATE,
    EAPITagType.CLAIM_STAKING,
    EAPITagType.USER_PASSIVE_INCOME,
    EAPITagType.USER_PASSIVE_INCOME_CLAIM,
    EAPITagType.USER_PASSIVE_INCOME_INCREASE_LEVEL,
    EAPITagType.WAI_LEADERBOARD
  ],
  endpoints: () => ({}),
});
