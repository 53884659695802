import React from "react";
import { UserContext } from "../contexts/user-context";

import { useWebApp } from "@vkruglikov/react-telegram-web-app";
import { SUPPORT_LINK } from "../../consts";

export const usePageSelectedBlockchainApi = () => {    
  const { user, isLoading,} = React.useContext(UserContext);

  const webApp = useWebApp();
    const handleOpenSupport = () => {
      webApp.openTelegramLink(SUPPORT_LINK);
    }

    return {
        isLoading,
        userName: user.telegram_user?.username,
        onOpenSupport: handleOpenSupport,
    }
}
