import React from "react";
import { TClaimState, TStaking } from "../../contexts/types";
import { ZoomInAnimation } from "../../page-animation";
import { useHapticFeedback } from "@vkruglikov/react-telegram-web-app";
import { TimeLeft } from "../time-left";
import { SmallCoin } from "../../page-claim/icons/small-coin";
import { BALANCE_COIN_SIZE_SMALL } from "../../../consts";
import { convertTimestamp, formatValues } from "../../../utils";

interface IProps {
  currentStake: TStaking[];
  canGetStaking: (dateWhenStaked: number, stakeDays: number) => boolean;
  onChangeStackToGet: React.Dispatch<
    React.SetStateAction<{
      amount: number;
      referralRewards: number;
      timestamp: number;
      days: number;
    } | null>
  >;
  getTimeLeft: (dateWhenStaked: number, stakeDays: number) => string;
  handleNew: () => void;
  user: TClaimState | undefined;
  isCompleted?: boolean;
}

export const StakeList: React.FC<IProps> = ({
  currentStake,
  canGetStaking,
  onChangeStackToGet,
  getTimeLeft,
  handleNew,
  user,
  isCompleted = false,
}) => {
  const [impactOccurred] = useHapticFeedback();

  return (
    <>
      {currentStake?.map((stake, index) => (
        <div style={{ width: "100%" }} key={stake.amount}>
          <ZoomInAnimation>
            <div
              key={stake.amount + index}
              className="staking-box staking-box-item"
            >
              {!isCompleted &&
                canGetStaking(stake.dateWhenStaked, stake.days) && (
                  <div
                    onClick={() => {
                      impactOccurred("heavy");
                      onChangeStackToGet({
                        amount: stake.reward,
                        referralRewards: stake.reward - stake.amount,
                        timestamp: stake.dateWhenStaked,
                        days: stake.days,
                      });
                    }}
                    className="claim-box"
                  >
                    <p className="claim-box-claim ab">Claim</p>
                  </div>
                )}
              <div className="col">
                <div className="item">
                  <div className="title">{isCompleted ? 'Ended in'  :'Ends in'}</div>
                  <div className="value">
                    {isCompleted ? (
                      convertTimestamp(stake.dateWhenStaked)
                    ) : (
                      <TimeLeft
                        dateWhenStaked={stake.dateWhenStaked}
                        stakeDays={stake.days}
                        getTimeLeft={getTimeLeft}
                      />
                    )}
                  </div>
                </div>
                <div className="item">
                  <div className="title">Percent</div>
                  <div className="value">{stake.percent}%</div>
                </div>
              </div>
              <div className="col gap">
                <div className="item">
                  <div className="title">Amount</div>
                  <div className="value dashboard-value">
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        width: "20px",
                      }}
                    >
                      <SmallCoin
                        width={BALANCE_COIN_SIZE_SMALL}
                        height={BALANCE_COIN_SIZE_SMALL}
                      />
                    </div>
                    <p>{formatValues(stake.amount)}</p>
                  </div>
                </div>
                <div className="item">
                  <div className="title">{isCompleted ? 'You\'ve earned' : 'You\'ll earn'}</div>
                  <div className="value purple dashboard-value ">
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        width: "20px",
                      }}
                    >
                      <SmallCoin
                        width={BALANCE_COIN_SIZE_SMALL}
                        height={BALANCE_COIN_SIZE_SMALL}
                      />
                    </div>
                    <p>{formatValues(stake.reward)}</p>
                  </div>
                </div>
              </div>
            </div>
          </ZoomInAnimation>
        </div>
      ))}
      {!isCompleted &&
        currentStake?.length < 3 &&
        Array.from({ length: 3 - currentStake.length }).map((_, i) => (
          <div style={{ width: "100%" }} key={i}>
            <ZoomInAnimation>
              <div
                className={`staking-box new staking-box-item ${
                  user?.balance === 0 ? "btn-disabled" : ""
                }`}
                onClick={() => handleNew()}
              >
                <span>+</span>
              </div>
            </ZoomInAnimation>
          </div>
        ))}
    </>
  );
};
