import React from 'react';

const coin = require("../../../img/new-small-coin.png");

interface IProps {
    width: number;
    height: number;
};

export const SmallCoin:React.FC<IProps> = ({width, height}) => {
    return <img src={coin} width={width} height={height} alt="" />
}