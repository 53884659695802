import React from "react";
import { ETab } from "../type";
import { motion } from "framer-motion";

interface IProps {
  activeTab: ETab;
  onChange: React.Dispatch<React.SetStateAction<ETab>>;
}

export const ToggleGroup: React.FC<IProps> = ({ activeTab, onChange }) => {
  return (
    <div className="boosters-toggle-wrapper">
      <h2
        className={`green-color boosters-header-item task-toggle-label ${
          activeTab === ETab.ACTIVE ? "selected" : ""
        }`}
        onClick={() => onChange(ETab.ACTIVE)}
      >
        <b>Active</b>
        {activeTab === ETab.ACTIVE && (
          <motion.div className="underline" layoutId="underline" />
        )}
      </h2>
      <h2
        className={`green-color boosters-header-item task-toggle-label ${
          activeTab === ETab.COMPLETED ? "selected" : ""
        }`}
        onClick={() => onChange(ETab.COMPLETED)}
      >
        <b>Completed</b>
        {activeTab === ETab.COMPLETED && (
          <motion.div className="underline" layoutId="underline" />
        )}
      </h2>
    </div>
  );
};
