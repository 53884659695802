import React from "react";

import "./index.scss";

const bg = require("../../img/main-bg.png") as string;
const time = require("./time.png") as string;

export const TimestampError: React.FC = () => {
  return (
    <div
      className="template-body"
      style={{
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        backgroundImage: `url(${bg})`,
        backgroundSize: "cover",
      }}
    >
      <img src={time} height="50%" alt="timestamp-error" />
      <p className="time-text ab">Don't play with time, Mate!</p>
    </div>
  );
};
