import { EWaiUpgradeType, TLeagueData } from "./models";

const expertsTeamIcon = require("./img/experts-team-icon.png") as string;
const marketAnalysisIcon = require("./img/market-analysis-icon.png") as string;
const predictionAccuracyIcon =
  require("./img/prediction-accuracy-icon.png") as string;
const tradingVolumeIcon = require("./img/trading-volume-icon.png") as string;

export const SELECTED_UPGRADE_DATA: Record<
  EWaiUpgradeType,
  { title: string; subTitle: string; btnLabel: string; icon: string }
> = {
  [EWaiUpgradeType.TRADING_EXPERTS]: {
    title: "Experts Team",
    subTitle:
      "Experts are key human resources that increase the efficiency of your trading activities, as well as expand your team of specialized employees.",
    btnLabel: "Upgrade",
    icon: expertsTeamIcon,
  },
  [EWaiUpgradeType.TRADING_VOLUME]: {
    title: "Trading Volume",
    subTitle:
      "Trading volume determines the amount that the trade bot can use for each transaction.",
    btnLabel: "Upgrade",
    icon: tradingVolumeIcon,
  },
  [EWaiUpgradeType.PREDICTION_ACCURACY]: {
    title: "Prediction Accuracy",
    subTitle:
      "The accuracy of forecasts affects the percentage of successful transactions that a trade bot can make.",
    btnLabel: "Upgrade",
    icon: predictionAccuracyIcon,
  },
  [EWaiUpgradeType.MARKET_ANALYSIS]: {
    title: "Market Analysis",
    subTitle:
      "Market analysis determines how effectively a trading bot can predict trends in the market.",
    btnLabel: "Upgrade",
    icon: marketAnalysisIcon,
  },
};


export const DEFAULT_LEAGUE_DATA: TLeagueData = {
  Newbie: {
    name: "Newbie",
    nextLeague: 'Enthusiast',
    slide: 0,
    data: undefined,
  },
  Enthusiast: {
    name: "Enthusiast",
    nextLeague: 'Expert',
    prevLeague: 'Newbie',
    slide: 1,
   data: undefined,
  },
  Expert: {
    name: "Expert",
    nextLeague: 'Master',
    prevLeague: 'Enthusiast',
    slide: 2,
   data: undefined,
  },
  Master: {
    name: "Master",
    nextLeague: 'Boss',
    prevLeague: 'Expert',
    slide: 3,
   data: undefined,
  },
  Boss: {
    name: "Boss",
    nextLeague: 'Prodigy',
    prevLeague: 'Master',
    slide: 4,
   data: undefined,
  },
  Prodigy: {
    name: "Prodigy",
    nextLeague: 'Virtuoso',
    prevLeague: 'Boss',
    slide: 5,
   data: undefined,
  },
  Virtuoso: {
    name: "Virtuoso",
    nextLeague: 'Elite',
    prevLeague: 'Prodigy',
    slide: 6,
   data: undefined,
  },
  Elite: {
    name: "Elite",
    nextLeague: 'Maestro',
    prevLeague: 'Virtuoso',
    slide: 7,
   data: undefined,
  },
  Maestro: {
    name: "Maestro",
    nextLeague: 'Legend',
    prevLeague: 'Elite',
    slide: 8,
   data: undefined,
  },
  Legend: {
    name: "Legend",
    nextLeague: 'Absolute',
    prevLeague: 'Maestro',
    slide: 9,
   data: undefined,
  },
  Absolute: {
    name: "Absolute",
    prevLeague: 'Legend',
    slide: 10,
   data: undefined,
  },
};