import React from "react";

import { CSSTransition } from "react-transition-group";
import { SmallCoin } from "../icons/small-coin";
import { BALANCE_COIN_SIZE_MEDIUM } from "../../../consts";

interface IProps {
  canShowWorkerModal: boolean;
  value: number | null;
  onClose: () => void;
  onClaim: () => void;
  disabled?: boolean;
}

export const ClaimModal: React.FC<IProps> = ({
  value,
  onClose,
  onClaim,
  disabled,
  canShowWorkerModal
}) => {
  return (
    <CSSTransition
      in={canShowWorkerModal}
      timeout={500}
      classNames="fade"
      unmountOnExit
    >
      <div className="overlay app-modal-root">
        <div
          className={`app-modal-content-wrapper task-modal-wrapper`}
          onClick={(e) => e.stopPropagation()}
        >
          <div className="close-modal ab" onClick={onClose}>
            x
          </div>
          <div className="app-modal-content task-modal-content">
            <div className="task-modal-desc">
              <div
                className="task-modal-icon"
                style={{background: 'transparent'} }
              >
               <div style={{ fontSize: 108 }}>🤖</div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "12px",
                  textAlign: "center",
                }}
              >
                <p className="task-modal-received">W-orked for you</p>
                <div className="task-modal-reward ab">
                  <SmallCoin width={BALANCE_COIN_SIZE_MEDIUM} height={BALANCE_COIN_SIZE_MEDIUM} />
                  <p>{value?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p>
                </div>
              </div>
            </div>
            <div className="task-modal-button-wrapper">
              <button
                onClick={onClaim}
                className={`app-modal-content-button ${disabled ? "disabled" : ""}`}
              >
                Claim
              </button>
            </div>
          </div>
        </div>
      </div>
    </CSSTransition>
  );
};
