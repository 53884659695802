export const TELEGRAM_LINK = "https://t.me/w_community";
export const SUPPORT_LINK = 'https://t.me/wcoinhub_9';

export const BALANCE_COIN_SIZE_XX_LARGE = 167;
export const BALANCE_COIN_SIZE_X_LARGE = 65;
export const BALANCE_COIN_SIZE_LARGE = 49;
export const BALANCE_COIN_SIZE_MEDIUM = 24;
export const BALANCE_COIN_SIZE_SMALL = 20;

export const MIN_FORMAT_COIN_VALUE = 100000000;

export const RUNE_REWARD = 22222;
