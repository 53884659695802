import React from "react";

import { CarouselTemplatePage } from "../carousel-template-page";

import "./index.scss";

const img1 = require("./img/slide-5-img-1.svg").default as string;
const img2 = require("./img/slide-5-img-2.svg").default as string;

export const Slide5: React.FC = () => {
  return (
    <CarouselTemplatePage title="Optimize your" subtitle="Experience">
      <div className="slides-wrapper carousel-template-page-children-slide3 slides-text-wrapper">
        
        <div className="slides3-images slides-text-wrapper" style={{width: '207px', height: "75px"}}>
          <img src={img1} alt="" height="75px" />
          <img src={img2} alt="" height="75px" />
        </div>
        <div className="slides3-desc-wrapper-root-wrapper">
          <p className="slides-desc slides-desc-title">Animation button</p>
          <p className="slides-desc">
            If you have a low-performance phone, you can disable animations to
            improve performance. Simply tap the animation icon to toggle this
            feature on or off.
          </p>
        </div>
        <div className="slides3-desc-wrapper-root-wrapper">
          <p className="slides-desc slides-desc-title">Information Guide</p>
          <p className="slides-desc">
            Tap the information icon to access this guide anytime. It provides
            all the essential details on how to maximize your WAI earnings and
            progress through leagues.
          </p>
        </div>
      </div>
    </CarouselTemplatePage>
  );
};
