import { Template } from "../template";
import { useWebApp } from "@vkruglikov/react-telegram-web-app";

const qr = require("./qr.png") as string;
const bg = require("./bg.png") as string;

const styles = {
  template: {
    height: "100vh",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "30px",
    gap: '20px',
  },
  typography: {
    fontSize: "50px",
    lineHeight: "41px",
    color: "#DFF2D8",
  },
  wrapper: {
    display: "flex",
  },
};

const TELEGRAM_LINK = 'https://t.me/w_community'

export const LaptopScreen = () => {
  const webApp = useWebApp();

  return (
    <Template isLoading={false} bg={bg}>
      <div style={{...styles.template, flexDirection:'column', alignItems: 'center'}}>
        <div style={{...styles.wrapper, flexDirection:'column', gap: '10px', maxWidth: '324px'}}>
          <p style={styles.typography} className="ab">Play</p>
          <p style={styles.typography} className="ab">mobile,</p>
          <p style={styles.typography} className="ab">Mate🧑🏼‍🚀</p>

        </div>

        <img src={qr} alt="qr" width="200px" height="200px" />

        <button
          className="app-modal-content-button"
          style={{
            padding: '16px',
            fontSize: '17px',
            color: '#fff',
            borderRadius: '22px',
            border: '1px solid transparent',
            backgroundColor: '#9747FF',
            margin: '0 auto',
            textAlign: 'center',
            width: '-webkit-fill-available',
            alignItems: 'center',
            maxWidth: '325px',
          }}
          onClick={() => {
            webApp.openTelegramLink(TELEGRAM_LINK);
          }}
        >
         <b>Join W-Community</b>
        </button>
      </div>
    </Template>
  );
};
