import React from "react";

import ProgressBar from "@ramonak/react-progress-bar";
import { SmallCoin } from "../../page-claim/icons/small-coin";
import { BALANCE_COIN_SIZE_SMALL } from "../../../consts";
import { TLevelData } from "../hooks";
import { formatValues } from "../../../utils";
import { ZoomInAnimation } from "../../page-animation";

interface IProps {
  data: TLevelData;
  matesCount: number;
  onClaimMateLevel: () => void;
}

export const Invite: React.FC<IProps> = ({
  data,
  matesCount,
  onClaimMateLevel,
}) => {
  return (
    <div className="mates-invite-root bebebe">
      <div className="mates-level">
        <div className="mates-avatar">
          <div className="mates-avatar-left-side">
            <ZoomInAnimation>
              <div className="list-item-short-username mates-list-item-short-username blur-bg-strong">
                🤝
              </div>
            </ZoomInAnimation>

            <div>
              <p className="mates-level-p gold-color ab">Level {data?.level}</p>
              <p className="mates-invite-p common-color">
                Invite {data?.mates} mates
              </p>
            </div>
          </div>
          <div className="mates-invite-button-wrapper">
            <button
              onClick={onClaimMateLevel}
              className={`mates-invite-button ab  ${
                matesCount >= data?.mates ? "" : "btn-disabled "
              }   `}
              disabled={!(matesCount >= data?.mates)}
            >
              Claim
            </button>
          </div>
        </div>
      </div>
      <div className="mates-progress">
        <ProgressBar
          completed={matesCount}
          maxCompleted={data?.mates}
          bgColor="#A385EA"
          baseBgColor="#E1DBEF"
          height="13px"
          margin="3px 0 0 0"
          labelSize="0"
          width="100%"
          className="mates-progress"
        />
        <div className="task-item-info-stack">
          <div className="task-item-reward ">
            <p className="common-color">+{formatValues(data?.levelRewards)}</p>
            <SmallCoin
              width={BALANCE_COIN_SIZE_SMALL}
              height={BALANCE_COIN_SIZE_SMALL}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
