import React from "react";

import { CarouselTemplatePage } from "../carousel-template-page";

import "./index.scss";
import { TradeButton } from "../../../action-button/trade-button";

const mates = require("./img/mates-onboarding.png") as string;

const img1 = require("./img/img1.png") as string;
const img2 = require("./img/img2.png") as string;
const img3 = require("./img/img3.png") as string;
const img4 = require("./img/img4.png") as string;

export const Slide2: React.FC = () => {
  return (
    <CarouselTemplatePage title="How does W-AI" label="work?">
      <div className="slides-wrapper">
        <div className="slides-item">
          <div style={{width: '157.67px', height: '63.9px'}}>
          <TradeButton />
          </div>
          <p className="slides-desc">
            Start trading every 8 hours by clicking "Trade" and enhance your
            bot’s efficiency by upgrading features.{" "}
          </p>
        </div>
        <div className="slides-item">
        <div style={{width: '100px', height: '75.89px'}}>

          <img width="100px" src={mates} alt="" />
          </div>
          <p className="slides-desc">
            Invite new experts to unlock advanced levels and maximize your
            trading speed.
          </p>
        </div>
        <div className="slides-item">
          <div className="slides2-img" style={{width: '299px', height: '62px'}}>
            <img src={img1} alt="" width="59px" />
            <img src={img2} alt="" />
            <img src={img3} alt="" />
            <img src={img4} alt="" />
          </div>
          <p className="slides-desc">
            Strategic upgrades and active participation help you progress
            through leagues and boost your passive income.
          </p>
        </div>
      </div>
    </CarouselTemplatePage>
  );
};
