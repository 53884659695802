import { ITask } from "./hooks";

export type TGroupTask = {
    [key: string]: ITask[];
}

export enum ETab {
    COLLABORATION = 'COLLABORATION',
    ACTIVE = "ACTIVE",
    COMPLETED = "COMPLETED",
}