import React from "react";

import { IFeatureLevel, IUserPassiveIncomeData } from "../../../feature/users";

import "./index.scss";

interface IProps {
  onClaim: () => void;
  label: string;
  featureLevel: IFeatureLevel;
  data: IUserPassiveIncomeData;
  disabled: boolean;
}

export const ActionButton: React.FC<IProps> = ({
  onClaim,
  label,
  data,
  featureLevel,
  disabled,
}) => {
  const isCurrencyMate = featureLevel.next_level_price.currency === "mate";

  const canUpgrade = () => {
    if (isCurrencyMate) {
      return data.mates_balance >= featureLevel.next_level_price.amount;
    } else {
      return data.balance >= featureLevel.next_level_price.amount ;
    }
  };
  
  return (
    <div className={`task-modal-button-wrapper ${ disabled && 'btn-disabled'}`}>
      <button
        onClick={canUpgrade() ? onClaim : undefined}
        className={`app-modal-content-button ${canUpgrade() ? "" : "disabled disabled-color"}`}
      >
        {label}
      </button>
    </div>
  );
};
