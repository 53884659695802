import React from 'react';

import "./index.scss";

interface FallingAnimationProps {
  imagePath: string;
  animationDuration: number;
  blockCount: number;
}

export const FallingAnimation: React.FC<FallingAnimationProps> = ({ imagePath, animationDuration, blockCount }) => {
  const delayInterval = (animationDuration ?? 0) / blockCount;

  return (
      <>
        {Array.from({ length: blockCount }).map((_, index) => {
          const size = Math.random() * (50 - 20) + 20;
          const rotation = Math.random() * 360; // Generate a random rotation angle

          return (
            <div 
              key={index} 
              className="cube" 
              style={{
                left: `${Math.random() * 95}vw`,
                animationDuration: `${animationDuration ?? 0}s, 1s`,
                animationName: 'fall, fade-out',
                animationTimingFunction: 'linear',
                animationFillMode: 'forwards',
                animationDelay: `${index * delayInterval}s, ${animationDuration ?? 0}s`,
                width: `${size}px`, 
                height: `${size}px`,
                
              }}
            >
              <img src={imagePath} alt="cube" style={{ width: '100%', height: '100%', transform: `rotate(${rotation}deg)` }} />
            </div>
          );
        })}
      </>
    );
}