import React from "react";

interface IProps {
  onClick: () => void;
}

export const ClaimButton: React.FC<IProps> = ({ onClick }) => (
  <div onTouchStart={onClick} className="claim-task-box" style={{backgroundColor: '#9747FF'}}>
    <p className="claim-box-claim ab" style={{fontSize: '23px'}}>Claim</p>
  </div>
);
