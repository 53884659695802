import {
    TUpdateClientArgs,
    useUpdateUserMutation,
  } from './components/feature/users';
  import { encryptData } from './utils';
  
  export const useUpdateUser = () => {
    const [updateUser, updateState] = useUpdateUserMutation();
  
    async function updateUserAsync(data: TUpdateClientArgs) {
      const result = await encryptData(data);
  
      if (result) {
        const { encryptedData, encryptedSymmetricKey, iv, authTag } = result;
        return updateUser({
          encryptedData,
          encryptedSymmetricKey,
          iv,
          data,
          authTag,
        }).unwrap();
      }
    }
  
    return [updateUserAsync, updateState as any];
  };