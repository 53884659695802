import React from "react";

import { Dice } from "./dice";

import "./index.scss";
import { useHapticFeedback } from "@vkruglikov/react-telegram-web-app";
import { SmallCoin } from "../../../../page-claim/icons/small-coin";
import { BALANCE_COIN_SIZE_MEDIUM } from "../../../../../consts";

interface IProps {
  onClick: (value: number) => void;
}

const MULTIPLY: Record<number, number> = {
  1: 2,
  2: 3,
  3: 5,
  4: 10,
  5: 15,
  6: 20,
};

export const LuckyDice: React.FC<IProps> = ({ onClick }) => {
  const [impactOccurred] =
  useHapticFeedback();
  const [diceClass, setDiceClass] = React.useState("dice");
  const [isRolled, setIsRolled] = React.useState(false);

  const [number, setNumber] = React.useState<number | null>(null);

  const isNumber = typeof number === "number";

  const handleChangeRolled = () => {
    setIsRolled(true);
  };

  const handleRollDice = () => {
    const dice = Math.floor(Math.random() * 6) + 1;
    setDiceClass(`dice show-${dice}`);

    setTimeout(() => {
      setNumber(dice);
      if (dice) {
        onClick(MULTIPLY[dice]);
      }
    }, 1200);
  };

  return (
    <>
      <div
        className={`app-modal-content ${
          isRolled ? "app-modal-content-justify" : ""
        }`}
      >
        {isRolled && (
          <div className="app-modal-content-label ab" style={{ fontSize: 28 }}>
            Lucky Dice
          </div>
        )}
        <Dice diceClass={diceClass} />
        {!isRolled && <div className="app-modal-content-label ab">Lucky Dice</div>}

        {!isRolled && (
          <>
            <div className="app-modal-content-description">
              <p>Try your luck and get up to 20x for tapping for 10 seconds</p>
            </div>
            <div className="app-modal-content-description">
              <p>3 times a day</p>
            </div>
          </>
        )}

        <div
          className="app-modal-content-bonus-wrapper"
          style={{ height: "29.9px" }}
        >
          {isRolled ? (
            <p className="app-modal-content-bonus">
              {isNumber ? (
                <>
                  {MULTIPLY[number]}X
                  <span
                    className="dice-time"
                    style={{
                      fontSize: "14px",
                    }}
                  >
                    / for 10 sec
                  </span>
                </>
              ) : null}
            </p>
          ) : (
            <>
               <SmallCoin width={BALANCE_COIN_SIZE_MEDIUM} height={BALANCE_COIN_SIZE_MEDIUM} />
                <p className="app-modal-content-bonus ab">Free</p>
            </>
          )}
        </div>
      </div>
      <button
        className={`app-modal-content-button ${isRolled ? 'btn-disabled' : ''}`}
        onClick={(e) => {
          e.stopPropagation();
          impactOccurred('heavy');
          if (!isRolled) {
            handleChangeRolled();
            handleRollDice();
          }
        }}
      >
       <b>Roll!</b>
      </button>
    </>
  );
};
