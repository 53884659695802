import React from "react";

import "../index.scss";

interface IProps extends React.PropsWithChildren {
  title: string;
  bg: string;
}

export const ContentWrapper: React.FC<IProps> = ({ title, bg, children }) => {
  return (
    <div
      className="content-wrapper-root"
    >
      <div className="content-wrapper-title main-heading ab">{title}</div>
      <div className="content-wrapper-body"       style={{
        backgroundImage: `url(${bg})`,
        backgroundSize: "cover",
      }}>{children}</div>
    </div>
  );
};
