import React from "react";

import { Theme } from "@radix-ui/themes";

import { useLeaderboardApi } from "./hooks";

import "./index.scss";

import { Header } from "./header";
import { UserContext } from "../contexts/user-context";
import { BackButton } from "@vkruglikov/react-telegram-web-app";
import { ROUTES } from "../routers";
import { EToggle } from "./types";
import { Stats } from "./stats";
import { TemplatePages } from "../template-pages";
import { useGetStatsQuery } from "../feature/users";
import { ERune } from "../hooks/rune/models";

const bg = require("./img/stats-bg.png") as string;

const iIcon = require("../../components/hooks/rune/img/i-icon.png") as string;

export const Leaderboard: React.FC = () => {
  const { currentValue, onChange, currentField } = useLeaderboardApi();
  const {
    isLoading: isUserLoading,
    user,
    onSetRuneStep,
  } = React.useContext(UserContext);
  const { data: stats, isLoading } = useGetStatsQuery();

  const divRef = React.useRef(null);

  // @ts-ignore
  const runeStep = user?.claim_state?.tasks?.rune?.step === ERune.O;
  
  return (
    <Theme>
      <TemplatePages
        isLoading={isUserLoading || isLoading}
        isFetching={false}
        bg={bg}
      >
        {runeStep && (
          <img
            src={iIcon}
            onClick={() =>
              onSetRuneStep?.({
                step: ERune.I,
                completed: false,
              })
            }
            alt=""
            className="c-rune-icon"
            style={{
              left: '50px',
              bottom: '50px',
            }}
            
          />
        )}
        <BackButton onClick={() => (window.location.hash = ROUTES.MAIN.PATH)} />
        <div
          className="page page-1 page-tasks main-wrapper page-leaderboard"
          style={{
            backgroundImage: `url(${bg})`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
          }}
        >
          <Header value={currentValue} onChange={onChange} />
          <div
            ref={divRef}
            className="leaderboard-content-wrapper bebebe"
            style={{ overflow: "hidden" }}
          >
            <div className="leaderboard-content-list">
              {/* {me && hasVisibleUser && telegramUsername && currentValue && currentField &&  (
              <ListItem
                currentValue={currentValue}
                username={telegramUsername}
                // @ts-ignore
                value={currentUserField()}
                isCurrentUser={true}
                icon={getIcon()}
              />
            )}
            {(users as TTLeaderboardItem[]).map((user, index) => (
                // @ts-ignore
                <ListItem  key={index} username={user.username} value={user[currentField]} isCurrentUser={isCurrentUser(user.username)} place={getPlace(index)}  icon={getIcon()} />
            ))} */}
              {currentField === EToggle.STATS && <Stats stats={stats} />}
            </div>
          </div>
        </div>
      </TemplatePages>
    </Theme>
  );
};
