import React from 'react';

import { useWizard } from 'react-use-wizard';

const placeholder = require("./img/staking-placeholder.png") as string;

export const StakingStep1: React.FC= () => {
  const { nextStep } = useWizard();

  return (
    <>
      <img className="placeholder staking-main-img" src={placeholder} alt="" />
      <div className="step1-actions">
      <button className="main-button step1-actions-btn ab staking-main-btn" onClick={() => nextStep()}><p>Stake</p></button>
      <a target="_blank" className="bottom-link step1-actions-label" href="https://telegra.ph/How-staking-works-04-20" rel="noreferrer">How staking works?</a>
      </div>
    </>
  );
};