import React from "react";

import "./index.scss";

import { Roadmap } from "./roadmap";
import { WalletBalance } from "./wallet-balance";
import { BottomAnimation } from "../../page-animation";

const roadMapIcon = require("../img/road-map-bg.png") as string;
const balanceIcon = require("../img/balance-bg.png") as string;

interface IProps {
  balance?: number;
}

export const Content: React.FC<IProps> = ({ balance }) => {
  return (
    <div
      style={{
        height: "100%",
      }}
    >
      <div className="wallet-content">
        <BottomAnimation>
          <Roadmap bg={roadMapIcon} />
        </BottomAnimation>
        <BottomAnimation>
          <WalletBalance balance={balance} bg={balanceIcon} />
        </BottomAnimation>
      </div>
    </div>
  );
};
