import React from "react";
import { LuckyDice } from "./dice";
import { useHapticFeedback } from "@vkruglikov/react-telegram-web-app";
import { SmallCoin } from "../../../page-claim/icons/small-coin";
import { BALANCE_COIN_SIZE_MEDIUM } from "../../../../consts";
import { formatValues } from "../../../../utils";

interface IProps {
  icon?: string | React.ReactElement;
  imageIcon?: string;
  title: string;
  description: string;
  upgrade: string | React.ReactElement;
  price: number | string;
  level?: number;
  onClick: (value?: number) => void;
  buttonLabel: string;
  disabled?: boolean;
  isMaxLevel?: boolean;
  isSecondStep?: boolean;
  onClose: (event: React.SyntheticEvent) => void;
}

export const ModalBoosterContent: React.FC<IProps> = ({
  icon,
  imageIcon,
  title,
  description,
  upgrade,
  price,
  level,
  onClick,
  buttonLabel,
  disabled,
  isMaxLevel,
  isSecondStep = false,
  onClose,
}) => {
  const [impactOccurred] = useHapticFeedback();

  return (
    <div
      className={`app-modal-content-wrapper ${
        isMaxLevel ? "is-max-level" : ""
      } `}
    >
      <div className="close-modal ab" onClick={onClose}>
        x
      </div>
      {isSecondStep ? (
        <LuckyDice onClick={onClick} />
      ) : (
        <>
          <div className="app-modal-content">
            <div className="app-modal-content-icon">
              {imageIcon ? <img src={imageIcon} width="100px" /> : icon}
            </div>
            <div className="app-modal-content-label ab">{title}</div>

            <div className="app-modal-content-description">
              <p>{isMaxLevel ? "You reach the maximum level" : description}</p>
            </div>
            {!isMaxLevel && (
              <>
                <div className="app-modal-content-description">
                  <p>{upgrade}</p>
                </div>
                <div className="app-modal-content-bonus-wrapper">
                <SmallCoin width={BALANCE_COIN_SIZE_MEDIUM} height={BALANCE_COIN_SIZE_MEDIUM} />{" "}
                  <p className="app-modal-content-bonus ab">{formatValues(price)}</p>
                  {level && (
                    <p className="app-modal-content-level"> / Level {level}</p>
                  )}
                </div>
              </>
            )}
          </div>
          {!isMaxLevel && (
            <button
              className={`app-modal-content-button ${
                disabled || isMaxLevel ? "btn-disabled" : ""
              }`}
              onClick={(e) => {
                e.stopPropagation();
                onClick();
                impactOccurred("heavy");
              }}
            >
             <b> {disabled ? "Insufficient funds" : buttonLabel}</b>
            </button>
          )}
        </>
      )}
    </div>
  );
};
