import { IFeatureLevel, IFeatures } from "../../feature/users";
import { EWaiUpgradeType } from "../models";

export function checkMaxLevelUpgrade(selectedUpgradeType: EWaiUpgradeType, allData: IFeatures): boolean {
    const nextLevel = allData[selectedUpgradeType].next_level;

    const copyAllData = { ...allData };
    delete copyAllData[selectedUpgradeType];
    
    return Object.values(copyAllData).some(item => {
        return nextLevel > item.current_level + 5;
    });
}

export function checkMinLevelUpgrade(
  keyData: IFeatureLevel,
  allData: IFeatures
): boolean {
  const { current_level } = keyData;

  for (let key in allData) {
    // @ts-ignore
    if (allData.hasOwnProperty(key) && allData[key] !== keyData) {
      // @ts-ignore
      const otherCurrentLevel = allData[key].current_level;
      if (current_level + 5 >= otherCurrentLevel) {
        return true;
      }
    }
  }

  return false;
}
