import React from "react";

import "./index.scss";
import { TStaking } from "../../contexts/types";

import date from "date-and-time";
import { calculateTotal, formatValue } from "../../../utils";
import { SmallCoin } from "../../page-claim/icons/small-coin";
import { BALANCE_COIN_SIZE_SMALL } from "../../../consts";

const bg = require("./img/placeholder-1.png") as string;

interface IProps {
  values: TStaking;
  onUpdateStaking: () => void;
}

export const StakingStep4: React.FC<IProps> = ({ values, onUpdateStaking }) => {
  function addDaysAndFormat(daysToAdd: number) {
    const now = new Date();

    const futureDate = date.addDays(now, daysToAdd);

    const formattedDate = date.format(futureDate, "MMM DD, YYYY");

    return formattedDate;
  }

  const endingDate = values.days ? addDaysAndFormat(values.days) : null;

  return (
    <>
      <div
        className="form-placeholder"
        style={{ backgroundImage: `url(${bg})`, backgroundRepeat: "no-repeat" }}
      >
        <div className="title big ab">INFO</div>
        <div className="staking-box">
          <div className="col">
            <div className="item">
              <div className="title">Duration</div>
              <div className="value">{values.days} days</div>
            </div>
            <div className="item">
              <div className="title">Percent</div>
              <div className="value">{values.percent}%</div>
            </div>
          </div>
          <div className="col">
            <div className="item">
              <div className="title">Amount</div>
              <div className="value dashboard-value">
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    width: "20px",
                  }}
                >
                  <SmallCoin width={BALANCE_COIN_SIZE_SMALL} height={BALANCE_COIN_SIZE_SMALL} />
                </div>{" "}
                <p>{formatValue(values.amount)}</p>
              </div>
            </div>

            <div className="item ">
              <div className="title">You'll earn</div>
              <div className="value purple dashboard-value">
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    width: "20px",
                  }}
                >
                  {" "}
                  <SmallCoin width={BALANCE_COIN_SIZE_SMALL} height={BALANCE_COIN_SIZE_SMALL} />
                </div>{" "}
                <p>{formatValue(calculateTotal(values.amount, values.percent))}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="form-buttons">
        {endingDate && (
          <div className="ending-date">
            <p>Ending on</p>
            <p>{endingDate}</p>
          </div>
        )}
        <button
          className="main-button step1-actions-btn ab"
          onClick={() => onUpdateStaking()}
        >
          STAKE
        </button>
      </div>
    </>
  );
};
