import React from "react";
import { Spinner } from "../spinner";

interface IProps extends React.PropsWithChildren {
  isLoading: boolean;
  isFetching: boolean;
  bg: string;
  isBgPositionCenter?: boolean;
}

export const TemplatePages: React.FC<IProps> = ({
  isLoading,
  isFetching,
  bg,
  isBgPositionCenter,
  children,
}) => {
  const content = () => {
    if (isLoading) {
      return <Spinner visible={isLoading} />;
    } else {
      return (
        <>
          {isFetching && <Spinner visible={isFetching} /> }
          {children}
        </>
      );
    }
  };

  return (
    <div
      className="template-body"
      style={{
        backgroundImage: `url(${bg})`,
        backgroundSize: "cover",
        height: "100vh",
        width: "100%",
        position: "relative",
        backgroundPosition: isBgPositionCenter? 'center' : undefined
      }}
    >
        {content()}
    </div>
  );
};
