import React from "react";

import { CSSTransition } from "react-transition-group";

import "../index.scss";
import { SmallCoin } from "../../page-claim/icons/small-coin";
import { BALANCE_COIN_SIZE_XX_LARGE } from "../../../consts";
import { formatValues } from "../../../utils";

interface IProps {
  amount?: number;
  onClose: () => void;
  onUpdate: () => void;
  disabled: boolean;
}

export const StakingModal: React.FC<IProps> = ({
  amount,
  onClose,
  onUpdate,
  disabled,
}) => {
  return (
    <CSSTransition
      in={!!amount}
      timeout={400}
      classNames="fade"
      unmountOnExit
    >
      <div className="overlay app-modal-root">
        <div
          className={`app-modal-content-wrapper task-modal-wrapper`}
          onClick={(e) => e.stopPropagation()}
        >
          <div className="close-modal ab" onClick={onClose}>
            x
          </div>
          <div className="app-modal-content task-modal-content">
            <div className="task-modal-desc">
              <SmallCoin width={BALANCE_COIN_SIZE_XX_LARGE} height={BALANCE_COIN_SIZE_XX_LARGE} />
              <div className="task-modal-reward ab">
                <p className="ab">+{formatValues(amount) || 0}</p>
              </div>
              <p className="task-modal-received">Received from staking</p>
            </div>
            <div className={`task-modal-button-wrapper ${disabled ? 'btn-disabled' : ''}`}>
              <button
                onClick={onUpdate}
                className={`app-modal-content-button`}
              >
                <b>Claim</b>
              </button>
            </div>
          </div>
        </div>
      </div>
    </CSSTransition>
  );
};
