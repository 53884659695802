import React from "react";

import { motion } from "framer-motion";

import { ETab } from "../enum";

import "./index.scss";

interface IProps {
  activeTab: ETab;
  onChange: React.Dispatch<React.SetStateAction<ETab>>;
}

export const ToggleGroup: React.FC<IProps> = ({ activeTab, onChange }) => {
  return (
    <div className="boosters-toggle-wrapper">
      <h2
        className={`boosters-header-item task-toggle-label dashboard-header-item ${
          activeTab === ETab.ACTIVE ? "selected" : ""
        }`}
        onClick={() => onChange(ETab.ACTIVE)}
      >
        <b>Active</b>
        {activeTab === ETab.ACTIVE && (
          <div
            className="dashboard-underline2 dashboard-underline"
            style={{ background: "#fff !important" }}
          />
        )}
      </h2>
      <h2
        className={`boosters-header-item task-toggle-label dashboard-header-item ${
          activeTab === ETab.COMPLETED ? "selected" : ""
        }`}
        onClick={() => onChange(ETab.COMPLETED)}
      >
        <b>Completed</b>
        {activeTab === ETab.COMPLETED && (
          <div
          className="dashboard-underline2 dashboard-underline"
          style={{ background: "#fff!important" }}
          />
        )}
      </h2>
    </div>
  );
};
