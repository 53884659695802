import React from "react";

import Confetti from "react-confetti";

import { TemplatePages } from "../template-pages";

import { WaiContent } from "./wai-content";
import { AnimationBg } from "./animation-bg";
import { WaiModal } from "./wai-modal";
import { useWaiApi, useWaiLeaderboardApi } from "./hooks";
import { LeagueListModal } from "./wai-content/league-list-modal";
import { OnboardingModal } from "./wai-content/onboarding-modal";
import "./index.scss";

const mainBg = require("./img/wai-main-bg.png") as string;
const logoCenter = require("./img/logo-center.png") as string;

export const Wai: React.FC = () => {
  const {
    showConfetti,
    setShowFirstTimeOnboarding,
    showLeague,
    setShowLeague,
    remainingTime,
    canClaim,
    onSelectUpgradeType,
    onClaim: handleClaim,
    onIncreaseLevel,
    onDynamicBgToggle,
    isDataLoading,
    isDataFetching,
    selectedUpgradeType,
    waiData,
    showDynamicBg,
    showOnboarding,
    setShowOnboarding,
  } = useWaiApi();

  const {
    onGetWaiLeaderboardByLeague,
    isLeaderboardLoading,
    leagueData,
    currentModalLeague,
  } = useWaiLeaderboardApi();

  const handleOpenLeagueModal = () => {
    setShowLeague(true);

    if (waiData?.league.name) {
      onGetWaiLeaderboardByLeague(waiData.league.name);
    }
  };

  return (
    <TemplatePages
      isLoading={isDataLoading}
      isFetching={isDataFetching}
      bg={mainBg}
      isBgPositionCenter={true}
    >
      {showConfetti && (
        <Confetti run={showConfetti} numberOfPieces={70} className="confetti" />
      )}
      {selectedUpgradeType && (
        <div
          className={`overlay app-modal-root ${
            selectedUpgradeType ? "blur" : ""
          }`}
        ></div>
      )}
      <WaiModal
        show={!!selectedUpgradeType}
        onClose={() => onSelectUpgradeType(null)}
        disabled={false}
        selectedUpgradeType={selectedUpgradeType}
        data={waiData}
        onIncreaseLevel={onIncreaseLevel}
      />
      <LeagueListModal
        show={showLeague}
        onClose={() => setShowLeague(false)}
        onGetWaiLeaderboardByLeague={onGetWaiLeaderboardByLeague}
        isLoading={isLeaderboardLoading}
        league={waiData?.league}
        leagueData={leagueData}
        currentModalLeague={currentModalLeague}
      />
      <OnboardingModal
        show={showOnboarding}
        onClose={() => {
          setShowOnboarding(false);
          setShowFirstTimeOnboarding(true);
        }}
      />
      {showDynamicBg && <AnimationBg />}
      {waiData && !showOnboarding ? (
        <>
          <img className="wai-main-logo" src={logoCenter} alt="" />
          <WaiContent
            onChangeUpgradeType={onSelectUpgradeType}
            data={waiData}
            onClaim={handleClaim}
            remainingTime={remainingTime}
            canClaim={canClaim}
            selectedUpgradeType={selectedUpgradeType}
            onModalLeagueOpen={handleOpenLeagueModal}
            onOnboardingModalOpen={() => setShowOnboarding(true)}
            showDynamicBg={showDynamicBg}
            onDynamicBgToggle={onDynamicBgToggle}
            onGetWaiLeaderboardByLeague={onGetWaiLeaderboardByLeague}
          />
        </>
      ) : null}
    </TemplatePages>
  );
};
