import React from "react";

import { usePageClaimApi } from "./hooks";
import { Links } from "./links";

import "./index.scss";
import { Template } from "../template";
import { ClaimButton } from "./claim-button";
import { ROUTES } from "../routers";
import { ClaimModal } from "./claim-modal";
import { Counter } from "../counter";
import { getClaimIcon } from "../../utils";
import { BottomAnimation } from "../page-animation";

const wallet = require("./img/wallet.svg").default as string;
const settings = require("./img/setting.svg").default as string;
// TODO: ADD AFTER RUNE
// const bg = require("../template/main-bg-choose.png");
// const runeBg = require("./img/rune-loader.png");
// const walletBg = require("../template/walletBg.png") as string;
const waiBg = require("../template/wai-bg.png") as string;

const stackingIcon = require("./img/staking.png") as string;
const rocketIcon = require("./img/rocket.png") as string;
const tasksIcon = require("./img/tasks.png") as string;
const matesIcon = require("./img/mates.png") as string;

export const PageClaim: React.FC = () => {
  const {
    isLoading,
    isFetching,
    isSuccess,
    energy,
    maxEnergy,
    onClickChange,
    counter,
    disableBtn,
    addToBalance,
    onGoToDashboard,
    workerModalStatus,
    onWorkerClaimBalance,
    onCloseModal,
    earnByMinute,
    claimState,
    activeTaskCount,
    setHasLoadedOnce,
    hasLoadedOnce,
  } = usePageClaimApi();

  const canShowWorkerModal = !!(
    workerModalStatus?.readyToShowWorkerModal &&
    !workerModalStatus?.alreadyOpenedModal &&
    earnByMinute
  );

  return (
    <Template
      isLoading={isLoading}
      isMainPage={true}
      isFetching={isFetching}
      setHasLoadedOnce={setHasLoadedOnce}
      hasLoadedOnce={hasLoadedOnce}
      bg={waiBg}
    >
      {canShowWorkerModal && (
        <div
          className={`overlay app-modal-root ${
            canShowWorkerModal ? "blur" : ""
          }`}
        ></div>
      )}
      <ClaimModal
        canShowWorkerModal={canShowWorkerModal}
        value={earnByMinute}
        onClose={onCloseModal}
        onClaim={onWorkerClaimBalance}
        disabled={isLoading}
      />
      {isSuccess && (
        <div className="page-claim">
          <div className="page-claim-header">
            <p className="green-color ab" style={{ fontSize: "22px" }}>
              W-COIN
            </p>
            <div className="icons page-claim-icons">
              <a href={`#${ROUTES.WALLET.PATH}`}>
                <img src={wallet} alt="" />
              </a>
              <a href={`#${ROUTES.CHANGE_BLOCKCHAIN.PATH}`}>
                <img src={settings} alt="" />
              </a>
            </div>
          </div>
          <div className="page-claim-content-wrapper">
            <div className="content main-wrapper page-claim-main-wrapper">
              <Counter
                counter={counter}
                additionalComponent={
                  <div className="page-claim-actions">
                    <a
                      href={`#${ROUTES.LEADERBOARD.PATH}`}
                      className="page-claim-leaderboard-link ab"
                    >
                      <button className="page-claim-leaderboard">Stats</button>
                    </a>
                    {/* <a className="page-claim-leaderboard-link page-claim-clans-link  ab disabled">
                    <button className="page-claim-leaderboard page-claim-clans">
                      Clans
                    </button>
                  </a> */}
                    <a
                      href={`#${ROUTES.WAI.PATH}`}
                      className="page-claim-leaderboard-link ab wai-button"
                    >
                      <button className="page-claim-leaderboard">W-AI</button>
                    </a>
                  </div>
                }
              />
              <div className="box-claim-wrapper">
                <ClaimButton
                  onClick={onClickChange}
                  energy={energy}
                  maxEnergy={maxEnergy}
                  clickMultiplier={addToBalance}
                  disableBtn={disableBtn}
                  claimIcon={getClaimIcon(claimState?.skins)}
                  isSpinnerSkin={claimState?.skins?.shuriken?.selected}
                />
                <BottomAnimation>
                  <div className="bottom-nav">
                    <Links
                      path={`#${ROUTES.MATES.PATH}`}
                      icon={matesIcon}
                      label="Mates"
                      width={30}
                      height={30}
                    />
                    <Links
                      path={`#${ROUTES.TASKS.PATH}`}
                      icon={tasksIcon}
                      label="Tasks"
                      activeTaskCount={activeTaskCount}
                      width={30}
                      height={30}
                    />
                    <Links
                      path={`#${ROUTES.DASHBOARD.PATH}`}
                      icon={stackingIcon}
                      label="Staking"
                      onClick={onGoToDashboard}
                      width={30}
                      height={30}
                    />
                    <Links
                      path={`#${ROUTES.BOOSTERS.PATH}`}
                      icon={rocketIcon}
                      label="Boost"
                      width={30}
                      height={30}
                    />
                  </div>
                </BottomAnimation>
              </div>
            </div>
          </div>
        </div>
      )}
    </Template>
  );
};
