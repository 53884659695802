import React from 'react';
import { ListWrapper } from '../list-wrapper';

import './index.scss';
import { MatesListItem } from './mates-list-item';
import { TMates } from '../../feature/users';

interface IProps {
    mates?: TMates;
    onGetClaimAll: () => void
}

export const MatesList:React.FC<IProps> =({mates, onGetClaimAll}) => {
    return <div className="mates-list-root">
            <ListWrapper mates={mates} onGetClaimAll={onGetClaimAll} >
                <div className="mates-list">
                {mates?.top_reward_mates?.map((mate, index) => (
                    <MatesListItem key={index} mate={mate} />
                ))}
                </div>
            </ListWrapper>
        </div>
}
