import React from "react";

interface IProps {
    dateWhenStaked: number;
    stakeDays: number;
    getTimeLeft: (dateWhenStaked: number, stakeDays: number) => string
}


export const TimeLeft:React.FC<IProps> = ({dateWhenStaked, stakeDays, getTimeLeft}) => {
    const [timeLeft, setTimeLeft] = React.useState(() => getTimeLeft(dateWhenStaked, stakeDays));
    React.useEffect(() => {
      const intervalId = setInterval(() => {
        setTimeLeft(getTimeLeft(dateWhenStaked, stakeDays));
      }, 1000); // вызывается каждую секунду
  
      // Очистка при размонтировании компонента
      return () => clearInterval(intervalId);
    }, [dateWhenStaked, stakeDays]);
  
    return <>{timeLeft}</>;
}