const newbie = require("./img/newbie.png") as string;
const enthusiast = require("./img/enthusiast.png") as string;
const expert = require("./img/expert.png") as string;
const master = require("./img/master.png") as string;
const boss = require("./img/boss.png") as string;
const prodigy = require("./img/prodigy.png") as string;
const virtuoso = require("./img/virtuoso.png") as string;
const maestro = require("./img/maestro.png") as string;
const elite = require("./img/elite.png") as string;
const legend = require("./img/legend.png") as string;
const absolute = require("./img/absolute.png") as string;

export const MAX_LEVEL = 50;

export const LEAGUE_PARAMS: Record<string, {icon: string, level: number, slide:number, name: string}> = {
    Newbie: {
        icon: newbie,
        level: 0,
        slide: 0,
        name: 'Newbie',
    },
    Enthusiast: {
        icon: enthusiast,
        level: 5,
        slide: 1,
        name: 'Enthusiast',
    },
    Expert: {   
        icon: expert,
        level: 10,
        slide: 2,
        name: 'Expert',
    },
    Master: {
        icon: master,
        level: 15,
        slide: 3,
        name: 'Master',
    },
    Boss: {
        icon: boss,
        level: 20,
        slide: 4,
        name: 'Boss',
    },
    Prodigy: {
        icon: prodigy,
        level: 25,
        slide: 5,
        name: 'Prodigy',
    },
    Virtuoso: {
        icon: virtuoso,
        level: 30, 
        slide: 6,
        name: 'Virtuoso',
    },
    Elite: {
        icon: elite,
        level: 35,   
        slide: 7,
        name: 'Elite',
    },
    Maestro: {
        icon: maestro,
        level: 40,     
        slide: 8,
        name: 'Maestro',
    },
    Legend: {
        icon: legend,
        level: 45,     
        slide: 9,
        name: 'Legend',
    },
    Absolute: {
        icon: absolute,
        level: MAX_LEVEL, 
        slide: 10,
        name: 'Absolute',
    }
}