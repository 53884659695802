import React from "react";

import "../index.scss";

import { CSSTransition } from "react-transition-group";
import { WSkinsModalContent } from "./wskins-modal-content";
import {
  TWSkinsItems,
} from "../../page-boosters/boosters/main-boosters/boosters-items/w-skins/types";
import { getCSkinIcon } from "../../../utils";

interface IProps {
  open: boolean;
  onClose: () => void;
  onUpdate: () => void;
  skinsToBuy: TWSkinsItems | null;
  balance: number;
  isPurchased?: boolean;
  isLoading?: boolean;
}

export const WSkinsModal: React.FC<IProps> = ({
  open,
  onClose,
  onUpdate,
  skinsToBuy,
  balance,
  isPurchased,
  isLoading,
}) => {

  return (
    <CSSTransition in={!!open} timeout={400} classNames="fade" unmountOnExit>
      <div className="overlay app-modal-root">
        {skinsToBuy && (
          <WSkinsModalContent
            onClick={onUpdate}
            icon={getCSkinIcon(skinsToBuy)}
            title={skinsToBuy.title}
            price={skinsToBuy.price}
            buttonLabel={isPurchased ? "Purchased" : "Buy"}
            onClose={onClose}
            disabled={skinsToBuy.price > balance || isPurchased || isLoading}
            isPurchased={isPurchased}
          />
        )}
      </div>
    </CSSTransition>
  );
};
