import React from "react";

import "./index.scss";
import { LEAGUE_PARAMS } from "../consts";

interface IProps {
  rate: number;
  league: string;
  onModalLeagueOpen: () => void;
}

export const CurrentLevelScores: React.FC<IProps> = ({
  rate,
  league,
  onModalLeagueOpen,
}) => {
  return (
    <div className="wai-additional-info">
      <div
        onClick={onModalLeagueOpen}
        className="wai-additional-info-root wai-additional-info-league"
      >
        <p className="wai-additional-info-text gray-color">League:</p>
        <p className="wai-additional-info-value ab">
          <img width="24px" src={LEAGUE_PARAMS[league].icon} alt="" />{" "}
          <p>{league}</p>
        </p>
      </div>
      <div
        className="wai-additional-info-root"
        style={{ alignItems: "flex-end" }}
      >
        <p className="wai-additional-info-text gray-color">Total speed:</p>
        <p className="wai-additional-info-value ab">{rate} WAI/H</p>
      </div>
    </div>
  );
};
