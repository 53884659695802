import { EAPITagType } from "../../../enums";
import { baseApi, JWT_TOKEN_KEY } from "../../../services";
import {
  EBlockchain,
  TaskVisitEveryDay,
  TBoosts,
  TClaim,
  TStaking,
} from "../../contexts/types";
import { ITask } from "../../page-tasks/hooks";
import { EWaiUpgradeType } from "../../wai/models";

import { ILeaderboardApiResponse, TLeague } from "./wai.models";

// Auth request
export type TAuthArgs = Pick<TUser, "username" | "password"> & { hash: string };

// Auth response
type TAuthResponse = {
  jwt: string;
  user: TUser;
};

export type TStats = {
  amount: string;
  totalTaps: string;
  users: string;
  dailyMates: string;
  onlineMates: string;
};

export type TRegisterArgs = Pick<
  TUser,
  "username" | "password" | "email" | "telegram_username"
>;

export type TRawTelegramUser = {
  id: number;
  is_bot: boolean;
  username: string;
  last_name: string;
  first_name: string;
  language_code: string;
  is_premium?: boolean;
};

export type TTask = {
  [key: string]:
    | boolean
    | {
        mates: number;
        isEnoughMates: boolean;
        completed: boolean;
        remainingInvites?: number;
      };
};

export type TSkin = {
  purchased: boolean;
  selected: boolean;
  type: string;
};

export type TSkins = {
  [key: string]: TSkin;
};

// Database user
export type TUser = {
  id: number; // database id
  password?: string; // need for strapi auth
  email?: string; // need for strapi auth
  username: string; // telegram username
  telegram_username?: string; // telegram username - copy of the username field
  clicks: number;
  referred_by_telegram_id: number;
  need_to_refresh_ls: boolean;
  telegram_id: number;
  balance: number;
  click_multiplier: number;
  click_multiplier_level: number;
  energy: number;
  max_energy: number;
  max_energy_level: number;
  energy_refill_multiplier: number;
  energy_refill_multiplier_level: number;
  last_click_at: number;
  is_telegram_joined: boolean;
  selected_blockchain?: EBlockchain;
  is_x_joined: boolean;
  task_invite_mate_claimed: boolean;
  is_website_opened: boolean;
  task_join_community_claimed: boolean;
  task_join_hub_claimed: boolean;
  task_upgrade_charger_to_max_claimed: boolean;
  task_try_staking_claimed: boolean;
  task_stake_level: string;
  task_click_level: string;
  current_mates_level: number;
  mates: TMate[];
  claims: TClaim;
  balance_from_clicks: number | null;
  boosts: TBoosts | null;
  stakes: TStaking[] | null;
  task_visit_every_day: TaskVisitEveryDay | null;
  reward_from_new_mates: number;
  task_balance_level: number;
  last_visited_at: number;
  task_yescoin_joined: boolean;
  raw_telegram_user: TRawTelegramUser;
  task_is_premium_claimed: boolean;
  tasks?: TTask;
  skins?: TSkins;
};

export type TClientResponse = {
  data: TUser[];
};

export type TClientArgs = {
  telegram_id?: number;
  id?: number;
};

export type TTLeaderboardItem = {
  username: string;
  balance: string;
};

export type TLeaderboardUser = {
  balance: string;
  clicks: string;
  mates_count: string;
};

export type TLeaderboard = {
  users: {
    by_balance: TTLeaderboardItem[];
    by_clicks: TTLeaderboardItem[];
    by_mates: TTLeaderboardItem[];
  };
  me: TLeaderboardUser;
};

export type TValidateUserInGroup = {
  is_member: boolean;
};

export type TMate = Pick<
  TUser,
  "telegram_username" | "username" | "balance_from_clicks"
> & { is_premium?: boolean };

export type TValidateUserInGroupArgs = {
  resource: string;
};

export interface TUpdateClientArgs extends Partial<TUser> {
  id: number;
  hash?: string;
}

export interface IMate {
  is_premium: boolean;
  reward: number;
  telegram_username: string;
}

export type TMates = {
  count_mates: number;
  top_reward_mates: IMate[];
  total_reward_balance: number;
};

export type TMatesGetRewardsResponse = {
  new_balance: number;
  prev_balance: number;
  reward: number;
};

export type TCreateStakeArgs = {
  days: number;
  stake_percent_from_balance: number;
};

export type TClaimStakingArgs = {
  timestamp: number;
};

export type TCreateStakeResponse = {
  data: {
    data: {
      balance: number;
      stakes: TStaking[];
    };
  };
};

export type TClaimStakingResponse = {
  data: {
    data: {
      balance: number;
      balance_from_clicks: number;
      stakes: TStaking[];
    };
  };
};

export interface ICurrentTradeSession {
  balance: number;
  rate: number;
  max_balance: number;
  started_at: number | null;
}
export interface INextLevelPrice {
  currency: string;
  amount: number;
}

export interface IFeatureLevel {
  current_level: number;
  current_level_rate: number;
  next_level: number;
  next_level_rate: number;
  next_level_price: INextLevelPrice;
}

export interface IFeatures {
  trading_experts: IFeatureLevel;
  trading_volume: IFeatureLevel;
  prediction_accuracy: IFeatureLevel;
  market_analysis: IFeatureLevel;
}

export interface ILeague {
  level: number;
  name: TLeague;
}

export interface IUserPassiveIncomeData {
  balance: number;
  mates_balance: number;
  current_trade_session: ICurrentTradeSession;
  features: IFeatures;
  league: ILeague;
}

export interface IUserPassiveIncomeResponse {
  status: string;
  data: IUserPassiveIncomeData;
}

export interface ICurrentTradeSession {
  balance: number;
  rate: number;
  max_balance: number;
  started_at: number | null;
}
export interface INextLevelPrice {
  currency: string;
  amount: number;
}

export interface IFeatureLevel {
  current_level: number;
  current_level_rate: number;
  next_level: number;
  next_level_rate: number;
  next_level_price: INextLevelPrice;
}

export interface IFeatures {
  trading_experts: IFeatureLevel;
  trading_volume: IFeatureLevel;
  prediction_accuracy: IFeatureLevel;
  market_analysis: IFeatureLevel;
}

export interface ILeague {
  level: number;
  name: TLeague;
}

export interface IUserPassiveIncomeData {
  balance: number;
  mates_balance: number;
  current_trade_session: ICurrentTradeSession;
  features: IFeatures;
  league: ILeague;
}

export interface IUserPassiveIncomeResponse {
  status: string;
  data: IUserPassiveIncomeData;
}

localStorage.removeItem(JWT_TOKEN_KEY);

const getInitData = () => {
  return (
    // @ts-ignore
    process.env.REACT_APP_INIT_DATA_HASH ?? window.Telegram.WebApp.initData
  );
};

const getClientTimestamp = () => {
  return Math.floor(Date.now() / 1000);
};

const clientApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    auth: builder.mutation<TAuthResponse, TAuthArgs>({
      query: (queryArg) => {
        // @ts-ignore
        return {
          url: `/api/auth/local`,
          method: "POST",
          body: {
            identifier:
              process.env.REACT_APP_DEVELOPMENT_ONLY_TEST_USER_EMAIL ??
              queryArg.username,
            password:
              process.env.REACT_APP_DEVELOPMENT_ONLY_TEST_USER_PASSWORD ??
              queryArg.password,
          },
        };
      },
      transformResponse: (response: TAuthResponse) => {
        localStorage.setItem(JWT_TOKEN_KEY, response.jwt);
        return response;
      },
      // @ts-ignore
      invalidatesTags: [EAPITagType.USERS],
    }),
    updateUser: builder.mutation<
      TUser,
      {
        encryptedData: string;
        encryptedSymmetricKey: string;
        iv: string;
        authTag: string;
        data: TUpdateClientArgs;
      }
    >({
      // @ts-ignore
      query: ({ data, encryptedData, encryptedSymmetricKey, iv, authTag }) => {
        const clientTimestamp = getClientTimestamp();
        const hash = getInitData();

        return {
          url: `/api/users/${data.id}?timestamp=${clientTimestamp}&hash=${hash}`,
          method: "PUT",
          body: { encryptedData, data, encryptedSymmetricKey, iv, authTag },
        };
      },
      transformResponse: (response: TUser) => response,
      invalidatesTags: [EAPITagType.UPDATE_USERS],
    }),
    getTasks: builder.query<ITask[], void>({
      query: () => {
        return {
          url: "/api/tasks",
          method: "GET",
        };
      },
      keepUnusedDataFor: 5,
      transformResponse: (response: ITask[]) => {
        return response;
      },
      providesTags: [EAPITagType.TASKS],
    }),
    getLeaderboard: builder.query<TLeaderboard, void>({
      query: () => ({
        url: "/api/users-permissions/users/leaderboard",
        method: "GET",
      }),
      transformResponse: (response: TLeaderboard) => {
        return response;
      },
      keepUnusedDataFor: 5,
      providesTags: [EAPITagType.LEADERBOARD],
    }),
    getValidateUserInGroup: builder.query<
      TValidateUserInGroup,
      TValidateUserInGroupArgs
    >({
      query: (queryArg) => {
        return {
          url: `/api/users-permissions/users/validate-user-in-group/${queryArg.resource}`,
          method: "GET",
        };
      },
      transformResponse: (response: TValidateUserInGroup) => {
        return response;
      },
      keepUnusedDataFor: 5,
      providesTags: [EAPITagType.VALIDATE_USER_IN_GROUP],
    }),
    getStats: builder.query<TStats[], void>({
      query: () => {
        return {
          url: "/api/stats",
          method: "GET",
        };
      },
      keepUnusedDataFor: 5,
      providesTags: [EAPITagType.STATS],
    }),
    getMates: builder.query<TMates, void>({
      query: () => {
        const clientTimestamp = getClientTimestamp();
        const hash = getInitData();

        return {
          url: `/api/users-permissions/users/get-mates?timestamp=${clientTimestamp}}&hash=${hash}`,
          method: "GET",
        };
      },
      keepUnusedDataFor: 5,
      transformResponse: (response: TMates) => {
        return response;
      },
      providesTags: [EAPITagType.MATES_LIST],
    }),
    getMatesGetRewards: builder.query<TMatesGetRewardsResponse, void>({
      query: () => {
        const clientTimestamp = getClientTimestamp();
        const hash = getInitData();

        return {
          url: `/api/users-permissions/users/claim-mates-reward?timestamp=${clientTimestamp}}&hash=${hash}`,
          method: "GET",
        };
      },
      keepUnusedDataFor: 5,
      transformResponse: (response: TMatesGetRewardsResponse) => {
        return response;
      },
      providesTags: [EAPITagType.MATES_GET_REWARDS],
    }),
    createStake: builder.mutation<TCreateStakeResponse, TCreateStakeArgs>({
      query: (queryArg) => {
        const clientTimestamp = getClientTimestamp();
        const hash = getInitData();

        return {
          url: `/api/users-permissions/users/stake/create?timestamp=${clientTimestamp}}&hash=${hash}`,
          method: "POST",
          body: {
            days: queryArg.days,
            stake_percent_from_balance: queryArg.stake_percent_from_balance,
          },
        };
      },
      transformResponse: (response: TCreateStakeResponse) => {
        return response;
      },
      invalidatesTags: [EAPITagType.STAKE_CREATE],
    }),
    claimStaking: builder.mutation<TClaimStakingResponse, TClaimStakingArgs>({
      query: (queryArg) => {
        const clientTimestamp = getClientTimestamp();
        const hash = getInitData();

        return {
          url: `/api/users-permissions/users/stake/claim?timestamp=${clientTimestamp}}&hash=${hash}`,
          method: "POST",
          body: {
            timestamp: queryArg.timestamp,
          },
        };
      },
      transformResponse: (response: TClaimStakingResponse) => {
        return response;
      },
      invalidatesTags: [EAPITagType.CLAIM_STAKING],
    }),
    getWai: builder.query<IUserPassiveIncomeResponse, void>({
      query: () => {
        const clientTimestamp = Math.floor(Date.now() / 1000);
        // @ts-ignore
        const hash = getInitData();

        return {
          url: `/api/users-permissions/passive-income/me?timestamp=${clientTimestamp}}&hash=${hash}`,
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
          keepUnusedDataFor: 5,
          providesTags: [EAPITagType.USER_PASSIVE_INCOME],
        };
      },
      transformResponse: (response: IUserPassiveIncomeResponse) => {
        return response;
      },
      providesTags: [EAPITagType.USERS],
    }),
    getWaiClaim: builder.query<IUserPassiveIncomeResponse, void>({
      query: (queryArg) => {
        const clientTimestamp = Math.floor(Date.now() / 1000);
        // @ts-ignore
        const hash = getInitData();

        return {
          url: `/api/users-permissions/passive-income/claim?timestamp=${clientTimestamp}}&hash=${hash}`,
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
          keepUnusedDataFor: 5,
          providesTags: [EAPITagType.USER_PASSIVE_INCOME_CLAIM],
        };
      },
      transformResponse: (response: IUserPassiveIncomeResponse) => {
        return response;
      },
      // @ts-ignore
      providesTags: [EAPITagType.USERS],
    }),
    getWaiIncreaseLevel: builder.query<
      IUserPassiveIncomeResponse,
      { type: EWaiUpgradeType }
    >({
      query: (queryArg) => {
        const clientTimestamp = Math.floor(Date.now() / 1000);
        // @ts-ignore
        const hash = getInitData();

        return {
          url: `/api/users-permissions/passive-income/increase-level/${queryArg.type}?timestamp=${clientTimestamp}}&hash=${hash}`,
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
          keepUnusedDataFor: 5,
          providesTags: [EAPITagType.USER_PASSIVE_INCOME_INCREASE_LEVEL],
        };
      },
      transformResponse: (response: IUserPassiveIncomeResponse) => {
        return response;
      },
      providesTags: [EAPITagType.USERS],
    }),
    getWaiLeaderboardByLeague: builder.query<ILeaderboardApiResponse, { league: TLeague }>({
      query: (queryArg) => {
        const clientTimestamp = Math.floor(Date.now() / 1000);
        // @ts-ignore
        const hash = getInitData();
        return {
          url: `/api/users-permissions/passive-income/leaderboard/${queryArg.league}?timestamp=${clientTimestamp}&hash=${hash}`,
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
          keepUnusedDataFor: 5,
          providesTags: [EAPITagType.WAI_LEADERBOARD],
        };
      },
      transformResponse: (response: ILeaderboardApiResponse) => {
        return response;
      },
      providesTags: [EAPITagType.USERS],
    }),
  }),
});

export const {
  useAuthMutation,
  useGetTasksQuery,
  useGetStatsQuery,
  useGetLeaderboardQuery,
  useUpdateUserMutation,
  useLazyGetValidateUserInGroupQuery,
  useLazyGetMatesGetRewardsQuery,
  useGetMatesQuery,
  useCreateStakeMutation,
  useClaimStakingMutation,
  useGetWaiQuery,
  useLazyGetWaiClaimQuery,
  useLazyGetWaiIncreaseLevelQuery,
  useLazyGetWaiLeaderboardByLeagueQuery,
} = clientApi;
