import React from "react";

import { useWizard } from "react-use-wizard";
import "./index.scss";
import { TStaking } from "../../contexts/types";
import { formatValue } from "../../../utils";

const bg = require("./img/placeholder-1.png") as string;

interface IProps {
  onChangeStacking: (value: Partial<TStaking>) => void;
  selectedValue?: number;
  balance: number;
}

export const PERCENT = [25, 50, 75, 100];

export const StakingStep3: React.FC<IProps> = ({
  onChangeStacking,
  balance,
}) => {
  const { nextStep } = useWizard();

  const [selected, setSelected] = React.useState<number | null>(null);

  const handleChange = (value: number) => {
    const valueWithPercentage = calculatePercentage(balance, value);
    setSelected(value);
    onChangeStacking({ amount: valueWithPercentage, stakePercentFromBalance: value});
  };

  function calculatePercentage(balance: number, percent: number) {
    return Math.floor((balance * percent) / 100);
  }

  return (
    <>
      <div
        className="form-placeholder"
        style={{ backgroundImage: `url(${bg})`, backgroundRepeat: "no-repeat" }}
      >
        <div className="title ab">Enter the amount</div>
        <div className="subtitle">
          Select the amount of coins you want to stake and confirm with the
          STAKE button
        </div>

        <p className="staking-step3-balance ab">{selected ? formatValue(calculatePercentage(balance, selected)) : 0}</p>

        <span className="slider-max">Available: {formatValue(balance)} </span>

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          {PERCENT.map((percent) => (
            <div
              key={percent}
              onClick={() => {
                handleChange(percent)
              }}
              className={`percent ${
                selected === percent ? "selected-btn" : ""
              } ${balance === 0 ? "btn-disabled" : ""}`}
              style={{
                width: "64px",
                height: "64px",
                textAlign: "center",
                fontSize: "18px",
                lineHeight: "65px",
                backgroundColor: selected === percent ? "#9747FF" : "#D9D9D9",
                borderRadius: "18px",
                justifyContent: "center",
                display: "flex",
              }}
            >
              <p>{percent === 100 ? 'MAX' : `${percent}%`}</p>
            </div>
          ))}
        </div>

      </div>

      <div className={`form-buttons ${selected ? "" : "btn-disabled"}`}>
        <button
          className="main-button step1-actions-btn ab"
          onClick={() => nextStep()}
        >
          Continue
        </button>
      </div>
    </>
  );
};
