import React from "react";

interface IProps {
  onClaim?: () => void;
}

export const TradeButton: React.FC<IProps> = ({ onClaim }) => {
  return (
    <div
      className={`wai-action-text-timer trade-button ab ${!onClaim && 'hide-margin-bottom' }`}
      style={{ backgroundColor: "#fff" }}
      onClick={onClaim}
    >
      Trade
    </div>
  );
};
