import { createContext, useState, useRef, useCallback, useEffect, useMemo } from "react";
import { FallingAnimation } from "../animation";

export type TConfig = {
  duration: number;
  blockCount: number;
  blockImage: string;
  blockSpeedRange?: { min: number; max: number };
  rotationRange?: { min: number; max: number };
  spawnSuccessChance?: number;
};

export type TContext = {
  handleActiveAnimation?: (config: TConfig) => void;
};

export const AnimationContext = createContext<TContext>({
  handleActiveAnimation: undefined,
});

export const AnimationProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [isActivated, setIsActivated] = useState(false);
  const [config, setConfig] = useState<TConfig | undefined>(undefined);

  const timeoutId = useRef<NodeJS.Timeout | null>(null);

  const handleActiveAnimation = useCallback((nextConfig: TConfig) => {
    if (!isActivated) {
      setConfig(nextConfig);
      setIsActivated(true);

      if (timeoutId.current) {
        clearTimeout(timeoutId.current);
      }

      timeoutId.current = setTimeout(() => {
        setIsActivated(false);
        setConfig(undefined);
      }, (nextConfig.duration * 1000) + 1000);
    }
  }, [isActivated]);

  useEffect(() => {
    return () => {
      if (timeoutId.current) {
        clearTimeout(timeoutId.current);
      }
    };
  }, []);

  const ctx: TContext = useMemo(
    () => ({
      handleActiveAnimation,
    }),
    [handleActiveAnimation]
  );

  const FallingAnimationMemo = useMemo(() => {
    if (!isActivated || !config) return null;

    return (
      <FallingAnimation
        key={config.blockImage}
        imagePath={config.blockImage}
        animationDuration={config.duration}
        blockCount={config.blockCount}
      />
    );
  }, [isActivated, config]);

  return (
    <AnimationContext.Provider value={ctx}>
      {FallingAnimationMemo}
      {children}
    </AnimationContext.Provider>
  );
};
