import React from "react";
import { Oval } from "react-loader-spinner";

interface IProps {
  visible: boolean;
}

export const Spinner: React.FC<IProps> = ({ visible }) => {
  return (
    <div className="app-spinner">
      <Oval
        visible={visible}
        height="100"
        width="100"
        color="#9747FF"
        secondaryColor="rgb(225, 219, 239)"
        ariaLabel="oval-loading"
        wrapperStyle={{}}
        wrapperClass=""
      />
    </div>
  );
};
