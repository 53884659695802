import React from "react";
import { ITask } from "../hooks";
import { SmallCoin } from "../../page-claim/icons/small-coin";
import { BALANCE_COIN_SIZE_MEDIUM } from "../../../consts";
import { formatValues } from "../../../utils";

interface IProps {
  currentTask: ITask | null;
  isJoinedToTelegram?: boolean;
}

export const VerifyReceived: React.FC<IProps> = ({ currentTask, isJoinedToTelegram }) => {
  return <>
  <div className="task-modal-reward ab">
      <SmallCoin width={BALANCE_COIN_SIZE_MEDIUM} height={BALANCE_COIN_SIZE_MEDIUM} />
        <p>+{formatValues(currentTask?.reward)}</p>
      </div>
      {isJoinedToTelegram  && <p className="task-modal-received">Received</p>}
    </>
};
