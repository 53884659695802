import React from "react";

import { useHapticFeedback } from "@vkruglikov/react-telegram-web-app";

import ProgressBar from "@ramonak/react-progress-bar";

import Tilt from "react-parallax-tilt";

import { Button } from "react-bootstrap";
import { ClaimCoin } from "../icons/claim-coin";
// @ts-ignore
import { useSpring, animated } from "react-spring";
const lightningIcon = require("../img/lightning.png") as string;

interface IProps {
  onClick: () => void;
  energy: number;
  maxEnergy: number;
  clickMultiplier: number;
  disableBtn: boolean;
  claimIcon: string;
  isSpinnerSkin?: boolean;
}

export const ClaimButton: React.FC<IProps> = ({
  onClick,
  energy,
  maxEnergy,
  clickMultiplier,
  disableBtn,
  claimIcon,
  isSpinnerSkin,
}) => {
  const [impactOccurred] = useHapticFeedback();

  const createDoubleTapPreventer = (timeout_ms: number) => {
    let dblTapTimer = 0;
    let dblTapPressed = false;

    return function (e: TouchEvent) {
      clearTimeout(dblTapTimer);
      if (dblTapPressed) {
        e.preventDefault();
        dblTapPressed = false;
      } else {
        dblTapPressed = true;
        // @ts-ignore
        dblTapTimer = setTimeout(() => {
          dblTapPressed = false;
        }, timeout_ms);
      }
    };
  };

  React.useEffect(() => {
    document.body.addEventListener(
      "touchstart",
      createDoubleTapPreventer(500),
      { passive: false }
    );
  }, []);

  const [clicks, setClicks] = React.useState<any>([]);

  const [[manualTiltAngleX, manualTiltAngleY], setManualTiltAngle] =
    React.useState([0, 0]);

  const vibrate = () => {
    const navigatorVibrate =
      navigator.vibrate ||
      // @ts-ignore
      navigator.webkitVibrate ||
      // @ts-ignore
      navigator.mozVibrate ||
      // @ts-ignore
      navigator.msVibrate;

    if (navigatorVibrate) {
      navigatorVibrate.call(navigator, [15]);
    } else {
      impactOccurred("light");
    }
  };

  const handleTouchStart = (event: any) => {
    event?.preventDefault?.();

    vibrate();

    const { clientX, clientY } = event.changedTouches[0];
    const buttonRect = event.target.getBoundingClientRect();
    const percentX = (clientX - buttonRect.left) / buttonRect.width;
    const percentY = (clientY - buttonRect.top) / buttonRect.height;
    let tiltAngleX = (percentY - 0.5) * 20;
    let tiltAngleY = (percentX - 0.5) * 20 * -1;
    setManualTiltAngle([tiltAngleX, tiltAngleY]);

    onClick();
  };

  const handleTouchEnd = (event: any) => {
    event?.preventDefault?.();

    const { clientX, clientY } = event.changedTouches[0];

    // Add new click to state
    setClicks((prevClicks: any) => [
      ...prevClicks,
      { id: Math.random(), x: clientX, y: clientY },
    ]);

    // Remove click from state after animation ends
    setTimeout(() => {
      setClicks((prevClicks: any) => prevClicks.slice(1));
    }, 1000);

    setTimeout(() => {
      setManualTiltAngle([0, 0]);
    }, 100);
  };

  const [rotation, setRotation] = React.useState(0);
  const [startRotation, setStartRotation] = React.useState(false);

  const { transform } = useSpring({
    from: { transform: `rotate(${rotation}deg)` },
    to: {
      transform: `rotate(${startRotation ? rotation + 360 : rotation}deg)`,
    },
    config: {
      tension: 150,
      friction: 30,
    },
    onRest: () => {
      if (startRotation) {
        setRotation((prevRotation) => prevRotation + 360);
        setStartRotation(false);
      }
    },
  });

  const timerId = React.useRef(null);

  const handleClick = () => {
    setStartRotation(true);
    setRotation((prevRotation) => prevRotation + 360);

    if (timerId.current) {
      clearTimeout(timerId.current);
    }

    // @ts-ignore
    timerId.current = setTimeout(() => {
      setStartRotation(false);
    }, 5000);
  };

  return (
    <div className="claim-button-root">
      {isSpinnerSkin ? (
        <animated.div
          style={{ transform }}
          onTouchStart={disableBtn ? undefined : handleClick}
        >
          <div className="box claim claim-button-wrapper">
            <Button
              className={`claim-button ${
                disableBtn ? "disabled" : ""
              } disable-dbl-tap-zoom`}
              onTouchStart={handleTouchStart}
              onTouchEnd={handleTouchEnd}
            >
              <Tilt
                tiltAngleXManual={manualTiltAngleX}
                tiltAngleYManual={manualTiltAngleY}
                tiltReverse={true}
                glareEnable={false}
                glareMaxOpacity={0.7}
                glareColor="#ffffff"
                glarePosition="bottom"
                glareBorderRadius="50%"
              >
                <ClaimCoin claimIcon={claimIcon} />
              </Tilt>
            </Button>
          </div>
        </animated.div>
      ) : (
        <Button
          className={`claim-button ${
            disableBtn ? "disabled" : ""
          } disable-dbl-tap-zoom`}
          onTouchStart={handleTouchStart}
          onTouchEnd={handleTouchEnd}
        >
          <Tilt
            tiltAngleXManual={manualTiltAngleX}
            tiltAngleYManual={manualTiltAngleY}
            tiltReverse={true}
            glareEnable={false}
            glareMaxOpacity={0.7}
            glareColor="#ffffff"
            glarePosition="bottom"
            glareBorderRadius="50%"
          >
            <ClaimCoin claimIcon={claimIcon} />
          </Tilt>
        </Button>
      )}
      {clicks.map((click: any) => (
        <div
          key={click.id}
          className="flyAndFade ab"
          style={{ position: "fixed", left: click.x, top: click.y }}
        >
          +{clickMultiplier}
        </div>
      ))}
      <ProgressBar
        completed={energy}
        maxCompleted={maxEnergy}
        bgColor="#A385EA"
        baseBgColor="#E1DBEF"
        height="13px"
        labelSize="0"
        width="100%"
        transitionTimingFunction="linear"
        className="mates-progress claim-progress"
      />
      <div className="claim-charger main-color">
        {energy === 0 ? "🪫" : <img width="18px" src={lightningIcon} />}
        <div className="claim-charger-count">
          <h1 className="claim-charger-count-text">{energy}</h1>
          <h1 className="claim-charger-count-text">/</h1>
          <h1 className="claim-charger-count-text">{maxEnergy}</h1>
        </div>
      </div>
    </div>
  );
};
