import React from "react";

import { EWaiUpgradeType } from "../../../models";

import "./index.scss";
import { IUserPassiveIncomeData } from "../../../../feature/users";
import { checkMaxLevelUpgrade } from "../../utils";

interface IProps {
  size: number;
  onChange: () => void;
  icon: string;
  classes: {
    root: string;
    level: string;
    notification: string;
  };
  data: IUserPassiveIncomeData;
  type: EWaiUpgradeType;
  scale: number;
  selectedUpgradeType: EWaiUpgradeType | null;
}

export const WaiUpgradeButton: React.FC<IProps> = ({
  size,
  onChange,
  icon,
  classes,
  data,
  type,
  scale,
  selectedUpgradeType,
}) => {

  const canUpgrade = () => {
    if (type && data?.features[type]) {
      if (type && data?.features[type].next_level_price.currency === 'mate')  {
        return data.mates_balance >= data?.features[type].next_level_price.amount;
      } else {
        return data.balance >= data?.features[type].next_level_price.amount ;
      }
    }
  };
  
  const buttonDisabled = React.useMemo(() => {
    if (type && data?.features) {
      return checkMaxLevelUpgrade(type, data?.features);
    }

    return false;
  }, [data?.features, selectedUpgradeType]);

  const showNotification = canUpgrade() && !buttonDisabled

  return (
    <div
      style={{
        width: size,
        height: size,
        transform: `translate(-50%, -50%) scale(${scale})`,
      }}
      className={`wai-upgrade-item ${classes.root}`}
      onClick={onChange}
    >
      <img
        className="wai-upgrade-item-img"
        src={icon}
        alt=""
        style={{ height: scale > 0.7 ? "60%" : "48%" }}
      />
      <div className={`white-color wai-upgrade-item-level ${classes.level}`}>
        <p style={{ fontSize: `${size / 8}px` }}>
          lvl
          {type ? data.features[type].current_level : null}
        </p>
      </div>

      {showNotification ? (
        <div className={classes.notification}></div>
      ) : null}
    </div>
  );
};
