import React from "react";

import ReactDOM from "react-dom/client";
import { WebAppProvider, useExpand } from "@vkruglikov/react-telegram-web-app";
import "antd/dist/reset.css";
import "./index.css";

import { ThirdwebProvider, trustWallet } from "@thirdweb-dev/react";

import { isMobile } from "react-device-detect";

import { Routers } from "./components/routers";

import { store } from "./store";
import { Provider } from "react-redux";
import { UserProvider } from "./components/contexts/user-context";
import { AnimationProvider } from "./components/contexts/animation-context";
import { LaptopScreen } from "./components/lapton-screen";
import { getAppKeyCoin } from "./utils";
import { TimestampError } from "./components/timestamp-error";
import { TonConnectUIProvider } from "@tonconnect/ui-react";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const NODE_ENV = process.env.NODE_ENV;

const App = () => {
  const [isExpanded, expand] = useExpand();
  expand();

  const [isTimestampError, setIsTimestampError] =
  React.useState<boolean>(false);

  React.useEffect(() => {
    if (NODE_ENV !== "development") {
      const overflow = 100;
      document.body.style.overflowY = "hidden";
      document.body.style.marginTop = `${overflow}px`;
      document.body.style.height = window.innerHeight + overflow + "px";
      document.body.style.paddingBottom = `${overflow}px`;
      window.scrollTo(0, overflow);

      // Cleanup function to reset styles when component unmounts
      return () => {
        document.body.style.overflowY = "";
        document.body.style.marginTop = "";
        document.body.style.height = "";
        document.body.style.paddingBottom = "";
        window.scrollTo(0, 0); // Reset scroll position
      };
    }
  }, []);


  const [now, setNow] = React.useState(Date.now());
  let previousTime = now;

  React.useEffect(() => {
    const interval = setInterval(() => {
      const currentTime = Date.now();
      
      if (currentTime > previousTime + 10 * 60 * 1000 || currentTime < previousTime - 10 * 60 * 1000) {
        setIsTimestampError?.(true);
      }
  
      setNow(currentTime);
      previousTime = currentTime;
    }, 1000);
  
    return () => clearInterval(interval);
  }, []);

  const isAppKeyCoin = getAppKeyCoin();


  if (isTimestampError) {
    return <TimestampError />;
  }

  if (!isMobile && !isAppKeyCoin) {
    return <LaptopScreen />;
  }

  return (
      <WebAppProvider>
          <TonConnectUIProvider manifestUrl={process.env.REACT_APP_WALLET_MANIFEST} >
          <ThirdwebProvider
            supportedWallets={[
              trustWallet({recommended: true}),
            ]}>
            <Provider store={store}>
              <AnimationProvider>
                <UserProvider>
                  <Routers />
                  <ToastContainer />
                </UserProvider>
              </AnimationProvider>
            </Provider>
          </ThirdwebProvider>
          </TonConnectUIProvider>
      </WebAppProvider>
  );
};

root.render(<App />);