import React from "react";
import CountUp from "react-countup";
import { SmallCoin } from "../page-claim/icons/small-coin";
import { BALANCE_COIN_SIZE_LARGE, MIN_FORMAT_COIN_VALUE } from "../../consts";
import { ZoomInAnimation } from "../page-animation";
import { formatValue } from "../../utils";

interface IProps {
  counter: {
    start: number;
    end: number;
  };
  additionalComponent?: React.ReactNode;
  isCollab?: boolean;
}

export const Counter: React.FC<IProps> = ({ counter, additionalComponent, isCollab }) => {
  return (
    <div className={`box box-balance ${isCollab ? 'custom-font-balance' : undefined}`}>
      <ZoomInAnimation>
        <h3 className={`plain-text font-size-16 page-claim-title ${isCollab ? 'white-color' : undefined}`}>
          Your Balance:
        </h3>
        <div className="balance claim-balance green-color">
          <SmallCoin
            height={BALANCE_COIN_SIZE_LARGE}
            width={BALANCE_COIN_SIZE_LARGE}
          />
          {counter.end > MIN_FORMAT_COIN_VALUE ? (
             <div className="balance ab">{formatValue(counter.end)}</div>
          ) : (
            <CountUp
              preserveValue={true}
              startOnMount={true}
              start={counter.start}
              end={counter.end}
              duration={0.7}
              separator=","
              className={`ab ${isCollab ? 'custom-font-balance' : undefined}`}
            />
          )}
        </div>
      </ZoomInAnimation>
      {additionalComponent}
    </div>
  );
};
