import React from "react";
import { TStats } from "../../feature/users";

import "./index.scss";
import { formatStatisticsValue } from "../../../utils";
import { SmallCoin } from "../../page-claim/icons/small-coin";
import { BALANCE_COIN_SIZE_X_LARGE } from "../../../consts";
import { ZoomInAnimation } from "../../page-animation";

const onlineImg = require("../img/online.png") as string;
const tapImg = require("../../../components/page-boosters/boosters/main-boosters/boosters-items/img/tap.png") as string;
const matesImg = require("../../../components/page-mates/img/main.png") as string;

interface IProps {
  stats?: TStats[];
}

export const Stats: React.FC<IProps> = ({ stats }) => {
  // @ts-ignore
  const currentStats = stats?.data[0]?.attributes?.contents as TStats;

  return (
    <div>
      <div className="statistics-wrapper">
        <ZoomInAnimation>
          {currentStats?.amount && (
            <div className="statistics-item stats-amount">
              <div className="plain-text font-size-16 page-claim-title ">
                Total W-Coin Amount
              </div>
              <div className="statistics-item-value balance claim-balance green-color ab statistics-item-value-label">
                <SmallCoin
                  width={BALANCE_COIN_SIZE_X_LARGE}
                  height={BALANCE_COIN_SIZE_X_LARGE}
                />
                {formatStatisticsValue(Number(currentStats.amount))}
              </div>
            </div>
          )}
        </ZoomInAnimation>
        <ZoomInAnimation>
          {currentStats?.totalTaps && (
            <div className="statistics-item">
              <div className="plain-text font-size-16 page-claim-title">
                Total Taps
              </div>
              <div className="statistics-item-value balance claim-balance green-color ab stats-value">
                <div className="statItemIcon"><img width="30px" src={tapImg} alt="" /></div>
                {formatStatisticsValue(Number(currentStats.totalTaps))}
              </div>
            </div>
          )}
        </ZoomInAnimation>
        <ZoomInAnimation>
          {currentStats?.users && (
            <div className="statistics-item">
              <div className="plain-text font-size-16 page-claim-title">
                Total Mates
              </div>
              <div className="statistics-item-value balance claim-balance green-color ab stats-value">
                <div className="statItemIcon"><img width="30px" src={matesImg} alt="" /></div>
                {formatStatisticsValue(Number(currentStats?.users))}
              </div>
            </div>
          )}
        </ZoomInAnimation>
        <ZoomInAnimation>
          {currentStats?.dailyMates && (
            <div className="statistics-item">
              <div className="plain-text font-size-16 page-claim-title">
                Daily Mates
              </div>
              <div className="statistics-item-value balance claim-balance green-color ab stats-value">
                <div className="statItemIcon">🤖</div>{" "}
                {formatStatisticsValue(Number(currentStats.dailyMates))}
              </div>
            </div>
          )}
        </ZoomInAnimation>
        <ZoomInAnimation>
          {currentStats?.onlineMates && (
            <div className="statistics-item">
              <div className="plain-text font-size-16 page-claim-title">
                Online Mates
              </div>
              <div className="statistics-item-value balance claim-balance green-color ab stats-value">
                <img
                  className="onlineImg statItemIcon"
                  src={onlineImg}
                  alt=""
                />
                {formatStatisticsValue(Number(currentStats?.onlineMates))}
              </div>
            </div>
          )}
        </ZoomInAnimation>
      </div>
    </div>
  );
};
