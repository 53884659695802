import React from "react";
import { useHapticFeedback } from "@vkruglikov/react-telegram-web-app";
import { SmallCoin } from "../../../page-claim/icons/small-coin";
import { BALANCE_COIN_SIZE_MEDIUM } from "../../../../consts";
import { formatValues } from "../../../../utils";

interface IProps {
  icon: string;
  title: string;
  price: number | string;
  onClick: () => void;
  buttonLabel: string;
  disabled?: boolean;
  onClose: (event: React.SyntheticEvent) => void;
  isPurchased?: boolean;
}

export const WSkinsModalContent: React.FC<IProps> = ({
  icon,
  title,
  price,
  onClick,
  buttonLabel,
  disabled,
  onClose,
  isPurchased,
}) => {
  const [impactOccurred] = useHapticFeedback();

  return (
    <div className="app-modal-content-wrapper">
      <div className="close-modal ab" onClick={onClose}>
        x
      </div>
      <>
        <div className="app-modal-content">
          <div className="app-modal-content-icon">
            {<img width={200} src={icon} alt="" />}
          </div>
          <div className="app-modal-content-label ab">{title}</div>
          <>
            <div
              className="app-modal-content-bonus-wrapper"
              style={{
                height: "30px",
              }}
            >
              {!isPurchased && (
                <>
                  <SmallCoin
                    width={BALANCE_COIN_SIZE_MEDIUM}
                    height={BALANCE_COIN_SIZE_MEDIUM}
                  />{" "}
                  <p className="app-modal-content-bonus ab">
                    {formatValues(price)}
                  </p>
                </>
              )}
            </div>
          </>
        </div>
        <button
          className={`app-modal-content-button ${
            disabled || isPurchased ? "btn-disabled" : ""
          }`}
          onClick={(e) => {
            e.stopPropagation();
            onClick();
            impactOccurred("heavy");
          }}
        >
          <b>
            {isPurchased
              ? "Purchased"
              : disabled
              ? "Insufficient funds"
              : buttonLabel}
          </b>
        </button>
      </>
    </div>
  );
};
