import React from "react";

export const Support = () => {
    return <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M13.1729 9.93777H13.1806" stroke="#282840" strokeWidth="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M9.73194 9.93777H9.73967" stroke="#282840" strokeWidth="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M6.29054 9.93777H6.29826" stroke="#282840" strokeWidth="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M15.8611 3.51236C19.2163 6.86757 19.2094 12.3025 15.8611 15.6517C13.2389 18.2748 9.35408 18.8421 6.17483 17.3718C5.70618 17.183 2.66856 18.0241 2.00936 17.3658C1.35102 16.7066 2.19218 13.669 2.00335 13.1995C0.533033 10.0211 1.10039 6.13542 3.72344 3.51236C6.23492 1.00003 9.92316 0.371735 13.0226 1.62833" stroke="#282840" strokeWidth="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    
}