import { TBoosterUpgrades, TMultiplierUpgrades } from "./types";

const TAP_UPGRADES: TBoosterUpgrades = {
    1: {
        level: 1,
        price: 2500,
        upgrade: 2
    },
    2: {
        level: 2,
        price: 5000,
        upgrade: 3
    },
    3: {
        level: 3,
        price: 10000,
        upgrade: 4
    },
    4: {
        level: 4,
        price: 20000,
        upgrade: 5,
    },
    5: {
        level: 5,
        price: 40000,
        upgrade: 6,
    },
    6: {
        level: 6,
        price: 80000,
        upgrade: 7, 
    },
    7: {
        level: 7,
        price: 160000,
        upgrade: 8, 
    },
    8: {
        level: 8,
        price: 320000,
        upgrade: 9, 
    },
    9: {
        level: 9,
        price: 640000,
        upgrade: 10, 
    },
    10: {
        level: 10,
        price: 1280000,
        upgrade: 11, 
    },
    11: {
        level: 11,
        price: 2560000,
        upgrade: 12, 
    },
    12: {
        level: 12,
        price: 5120000,
        upgrade: 13, 
    },
    13: {
        level: 13,
        price: 10240000,
        upgrade: 14, 
    },
    14: {
        level: 14,
        price: 20480000,
        upgrade: 15, 
    },
    15: {
        level: 15,
        price: 40960000,
        upgrade: 16, 
    },
    16: {
        level: 16,
        price: 81920000,
        upgrade: 17, 
    },
    17: {
        level: 17,
        price: 163840000,
        upgrade: 18, 
    },
    18: {
        level: 18,
        price: 327680000,
        upgrade: 19, 
    },
    19: {
        level: 19,
        price: 655360000,
        upgrade: 20, 
    },
    20: {
        level: 20,
        price: 1310720000,
        upgrade: 21, 
    }
};

export const CHARGER_UPGRADES: TBoosterUpgrades = {
    1: {
        level: 1,
        price: 500,
        upgrade: 2
    },
    2: {
        level: 2,
        price: 3000,
        upgrade: 3
    },
    3: {
        level: 3,
        price: 18000,
        upgrade: 4  
    },
    4: {
        level: 4,
        price: 48000,
        upgrade: 5 
    },
};

const MAXIMIZER_UPGRADES: TBoosterUpgrades = {
    1: {
        level: 1,
        price: 2500,
        upgrade: 1500,
    },
    2: {
        level: 2,
        price: 5000,
        upgrade: 2000, 
    }, 
    3: {
        level: 3,
        price: 10000,
        upgrade: 2500, 
    },
    4: {
        level: 4,
        price: 20000,
        upgrade: 3000, 
    },
    5: {
        level: 5,
        price: 40000,
        upgrade: 3500, 
    },
    6: {
        level: 6,
        price: 80000,
        upgrade: 4000, 
    },
    7: {
        level: 7,
        price: 160000,
        upgrade: 4500, 
    },
    8: {
        level: 8,
        price: 320000,
        upgrade: 5000, 
    },
    9: {
        level: 9,
        price: 640000,
        upgrade: 5500, 
    },
    10: {
        level: 10,
        price: 1280000,
        upgrade: 6000, 
    },
    11: {
        level: 11,
        price: 2560000,
        upgrade: 6500, 
    },
    12: {
        level: 12,
        price: 5120000,
        upgrade: 7000, 
    },
    13: {
        level: 13,
        price: 10240000,
        upgrade: 7500, 
    },
    14: {
        level: 14,
        price: 20480000,
        upgrade: 8000, 
    },
    15: {
        level: 15,
        price: 40960000,
        upgrade: 8500, 
    },
    16: {
        level: 16,
        price: 81920000,
        upgrade: 9000, 
    },
    17: {
        level: 17,
        price: 163840000,
        upgrade: 9500, 
    },
    18: {
        level: 18,
        price: 327680000,
        upgrade: 10000, 
    },
    19: {
        level: 19,
        price: 655360000,
        upgrade: 10500, 
    },
    20: {
        level: 20,
        price: 1310720000,
        upgrade: 11000, 
    }
};

export const UPGRADES = {
    TAP_UPGRADES,
    CHARGER_UPGRADES,
    MAXIMIZER_UPGRADES,
};

export const MAX_LEVEL_MAXIMIZER_UPGRADES = Object.keys(MAXIMIZER_UPGRADES).length;
export const MULTIPLIER_UPGRADES: TMultiplierUpgrades = { price: 512_000,}
export const WORKER_UPGRADES: TMultiplierUpgrades = { price: 128_000,}