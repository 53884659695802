import { ILeaderboardApiResponse, TLeague } from "../feature/users/wai.models";

export enum EWaiUpgradeType {
    MARKET_ANALYSIS = 'market_analysis',
    PREDICTION_ACCURACY = 'prediction_accuracy',
    TRADING_VOLUME = 'trading_volume',
    TRADING_EXPERTS = 'trading_experts',
}


export type TLeagueDataItem = {
  name: TLeague;
  nextLeague?: TLeague;
  prevLeague?: TLeague;
  data?: ILeaderboardApiResponse;
  slide: number;
}

export type TLeagueData = {
    [key in TLeague]: TLeagueDataItem;
  };