import React from "react";

import "./index.scss";
import { EBooster } from "../types";

import { BoostResetTimer } from "./timer";
import { ZoomInAnimation } from "../../../page-animation";
const dice = require("../../../../img/new-icons/dice.png") as string;
const battery = require("../../../../img/new-icons/battery.png") as string;
interface IProps {
  onOpen: (booster: EBooster) => void;
  availableEnergyBoosts: number;
  availableDiceBoosts: number;
}

export const FreeBoosters: React.FC<IProps> = ({
  onOpen,
  availableEnergyBoosts,
  availableDiceBoosts,
}) => {
  return (
    <div className="free-boosters-root">
      <h2 className="header green-color">
        <b>Free daily boosters</b>
      </h2>
      <div className="free-boosters-items">
        <div
          className={`bebebe free-boosters-item ${
            availableEnergyBoosts === 0 ? "btn-disabled booster-disabled" : ""
          }`}
          onClick={() => onOpen(EBooster.FULL_BATTERY)}
        >
          <div className="free-boosters-item-desc">
            <p className="green-color">Full Battery</p>
            <span className="free-boosters-item-available common-color">
              {availableEnergyBoosts}/3{" "}
              {availableEnergyBoosts === 0 ? <BoostResetTimer /> : "available"}{" "}
            </span>
          </div>
          <ZoomInAnimation>
            <img src={battery} alt="" width={30} height={34} />
          </ZoomInAnimation>
        </div>
        <div
          className={`bebebe free-boosters-item green-color ${
            availableDiceBoosts === 0 ? "btn-disabled booster-disabled" : ""
          }`}
          onClick={() => onOpen(EBooster.LUCKY_DICE)}
        >
          <div className="free-boosters-item-desc">
            <p className="green-color">Lucky Dice</p>
            <span className="free-boosters-item-available common-color">
              {availableDiceBoosts}/3{" "}
              {availableDiceBoosts === 0 ? <BoostResetTimer /> : "available"}{" "}
            </span>
          </div>
          <ZoomInAnimation>
            <img src={dice} alt="" width={30} />
          </ZoomInAnimation>
        </div>
      </div>
    </div>
  );
};
