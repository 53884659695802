import React from "react";

// @ts-ignore
import * as ToggleGroup from "@radix-ui/react-toggle-group";
import { EToggle } from "../types";

interface IProps {
  value: EToggle;
  onChange: (value: EToggle) => void;
}

export const Header: React.FC<IProps> = ({ value, onChange }) => {
  return (
    <div className="leaderboard-header">
      <div> 
        <p className="leaderboard-heading ab">
          <span style={{color: '#fff'}}>Stats</span>
        </p>
      </div>

      {/* <div className="leaderboard-toggler-wrapper">
        <ToggleGroup.Root
          className="ToggleGroup"
          type="single"
          defaultValue={EToggle.STATS}
          aria-label="Text alignment"
          value={value}
          onValueChange={onChange}
        >
          <ToggleGroup.Item
            className="ToggleGroupItem btn-disabled"
            aria-label="Left aligned"
            value={EToggle.CLANS}
          >
            Clans coming soon
          </ToggleGroup.Item>
          <ToggleGroup.Item
            className="ToggleGroupItem"
            value={EToggle.STATS}
            aria-label="Center aligned"
          >
            Stats
          </ToggleGroup.Item>
          <ToggleGroup.Item
            className="ToggleGroupItem"
            aria-label="Right aligned"
            value={EToggle.TAPS}
          >
            Taps
          </ToggleGroup.Item> 
        </ToggleGroup.Root>
      </div> */}
    </div>
  );
};
