import React from "react";

import "./index.scss";

interface IProps {
  title?: string;
  subtitle?: string;
}

export const Title: React.FC<IProps> = ({ title, subtitle }) => {  
  return (
    <div className="wai-title-root">
      <p className="white-color ab" style={{fontSize: '24px'}}>{title}</p>
      <p className="white-color" style={{fontSize: '14px'}}>
        {subtitle}
      </p>
    </div>
  );
};
