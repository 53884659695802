import React from "react";

import { EToggle } from "./types";

export const useLeaderboardApi = () => {
  
  const [currentValue, setCurrentValue] = React.useState<EToggle>(
    EToggle.STATS
  );

  const handleChange = (value: EToggle) => {
    if (value) {
      setCurrentValue(value);
    }
  };

  const FIELDS: Record<EToggle, string> = {
    // [EToggle.WCOIN]: "balance",
    // [EToggle.CLANS]: "CLANS",
    [EToggle.STATS]: "STATS",
  };

  return {
    onChange: handleChange,
    currentValue,
    // isLoading,
    // me: data?.me,
    // @ts-ignore
    // users: data?.users ? data.users[currentValue as string] : ([] as TTLeaderboardItem[]),
    currentField: FIELDS?.[currentValue],
  };
};
