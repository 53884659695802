import React from "react";

import "./index.scss";

const arrowIcon = require("../img/arrow.png") as string;

interface IProps {
  icon: string;
  currentLevelRate: number;
  currentLevel: number;
  nextLevelRate: number;
  nextLevel: number;
}

export const Upgrade: React.FC<IProps> = ({ icon, currentLevelRate, currentLevel, nextLevelRate, nextLevel }) => {
  return (
    <div className="wai-modal-upgrade-root">
      <div className="wai-modal-upgrade-item">
        <div style={{width: "150px", height: '150px'}}>
        <img src={icon} alt="" width="150px" />
        </div>
        <div className="wai-upgrade-values">
            <p className="ab" style={{ fontSize: '18px', color: '#B175FF' }}>+{currentLevelRate} WAI</p>
            <p className="white-color" style={{ fontSize: '14px' }}>lvl. {currentLevel}</p>
        </div>
      </div>
      <div>
        <img src={arrowIcon} alt="" style={{marginTop: '-50px', width: '36px'}} />
      </div>
      <div className="wai-modal-upgrade-item">
      <div style={{width: "150px", height: '150px'}}>
        <img src={icon} alt="" width="150px" />
        </div>
        <div className="wai-upgrade-values">
            <p className="ab" style={{ fontSize: '18px', color: '#B175FF' }}>+{nextLevelRate} WAI</p>
            <p className="white-color" style={{ fontSize: '14px' }}>lvl. {nextLevel}</p>
        </div>
      </div>
    </div>
  );
};
