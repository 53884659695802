import React, { useEffect, useRef } from 'react';
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import "./index.scss";

export const AnimationBg = () => {
  const canvasRef = useRef();
  const debugObject = {
    count: 200000,
    size: 15,
    branches: 4,
    radius: 3,
    randomness: 0.65,
    randomnessPower: 3.2,
    innerColor: '#bd21f0',
    outerColor: '#1627de',
    rotationSpeed: 0.3,
    baseRadius: 0.5, // New parameter for the base radius
  };

  useEffect(() => {
    const sizes = {
      width: window.innerWidth,
      height: window.innerHeight,
    };

    const scene = new THREE.Scene();
    const geometry = new THREE.BufferGeometry();
    let material: any = null;
    let mesh: any = null;

    const generateGalaxy = () => {
      if (geometry) {
        geometry.dispose();
        if (material) material.dispose();
        if (mesh) scene.remove(mesh);
      }

      const positions = new Float32Array(debugObject.count * 3);
      const scales = new Float32Array(debugObject.count);
      const colors = new Float32Array(debugObject.count * 3);
      const randomness = new Float32Array(debugObject.count * 3);

      const innerColor = new THREE.Color(debugObject.innerColor);
      const outerColor = new THREE.Color(debugObject.outerColor);

      for (let i = 0; i < debugObject.count; i++) {
        const randomRadius = debugObject.baseRadius + Math.random() * (debugObject.radius - debugObject.baseRadius);
        const i3 = i * 3;

        const angle = (i % debugObject.branches) / debugObject.branches * Math.PI * 2;

        positions[i3] = Math.cos(angle) * randomRadius;
        positions[i3 + 1] = 0;
        positions[i3 + 2] = Math.sin(angle) * randomRadius;

        scales[i] = Math.random();

        const pointColor = innerColor.clone();
        pointColor.lerp(outerColor, randomRadius / debugObject.radius);

        colors[i3] = pointColor.r;
        colors[i3 + 1] = pointColor.g;
        colors[i3 + 2] = pointColor.b;

        randomness[i3] = Math.pow(Math.random(), debugObject.randomnessPower) * (Math.random() > 0.5 ? -1 : 1) * debugObject.randomness * randomRadius;
        randomness[i3 + 1] = Math.pow(Math.random(), debugObject.randomnessPower) * (Math.random() > 0.5 ? -1 : 1) * debugObject.randomness * randomRadius;
        randomness[i3 + 2] = Math.pow(Math.random(), debugObject.randomnessPower) * (Math.random() > 0.5 ? -1 : 1) * debugObject.randomness * randomRadius;
      }

      geometry.setAttribute('position', new THREE.BufferAttribute(positions, 3));
      geometry.setAttribute('aScale', new THREE.BufferAttribute(scales, 1));
      geometry.setAttribute('aColor', new THREE.BufferAttribute(colors, 3));
      geometry.setAttribute('aRandomness', new THREE.BufferAttribute(randomness, 3));

      material = new THREE.ShaderMaterial({
        vertexShader: `
            uniform float uSize;
            uniform float uTime;
            uniform float uRotationSpeed;

            attribute float aScale;
            attribute vec3 aColor;
            attribute vec3 aRandomness;

            varying vec2 vUv;
            varying vec3 vColor;

            void main() {
                vec4 bodyPosition = modelMatrix * vec4(position, 1.0);
                float angle = atan(bodyPosition.x, bodyPosition.z);
                float distanceToCenter = length(bodyPosition.xz);
                float angleOffset = (1.0 / distanceToCenter) * uTime * uRotationSpeed;
                angle += angleOffset;
                bodyPosition.x = cos(angle) * distanceToCenter;
                bodyPosition.z = sin(angle) * distanceToCenter;
                bodyPosition.xyz += aRandomness.xyz;
                vec4 viewPosition = viewMatrix * bodyPosition;
                vec4 projectionPosition = projectionMatrix * viewPosition;
                gl_Position = projectionPosition;
                gl_PointSize = uSize * aScale;
                gl_PointSize *= (1.0 / -viewPosition.z);
                vUv = uv;
                vColor = aColor;
            }
        `,
        fragmentShader: `
            varying vec3 vColor;
            varying vec2 vUv;

            void main() {
                float distanceFromCenter = 1.0 - distance(gl_PointCoord, vec2(0.5));
                distanceFromCenter = pow(distanceFromCenter, 6.0);
                vec3 finalColor = mix(vec3(0.0), vColor, distanceFromCenter);
                gl_FragColor = vec4(finalColor, 1.0);
            }
        `,
        blending: THREE.AdditiveBlending,
        // @ts-ignore
        sizeAttenuation: true,
        depthWrite: false,
        transparent: true,
        uniforms: {
          uSize: { value: debugObject.size },
          uTime: { value: 0 },
          uRotationSpeed: { value: debugObject.rotationSpeed },
        },
      });

      mesh = new THREE.Points(geometry, material);
      scene.add(mesh);
    };

    generateGalaxy();

    const camera = new THREE.PerspectiveCamera(75, sizes.width / sizes.height, 0.1, 100);
    camera.position.set(0, 5, 0); // Position camera above the galaxy
    camera.lookAt(new THREE.Vector3(0, 0, 0));
    scene.add(camera);

    // @ts-ignore
    const controls = new OrbitControls(camera, canvasRef.current);
    controls.enableDamping = true;
    controls.dampingFactor = 0.07;
    controls.rotateSpeed = 0.03;

    // Set the minimum and maximum zoom distance
    controls.minDistance = 1;
    controls.maxDistance = 2;

    const renderer = new THREE.WebGLRenderer({
      canvas: canvasRef.current,
    });
    renderer.setSize(sizes.width, sizes.height);
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));

    const clock = new THREE.Clock();

    const tick = () => {
      const elapsedTime = clock.getElapsedTime();

      if (material) {
        material.uniforms.uTime.value = elapsedTime;
      }

      controls.update();
      renderer.render(scene, camera);

      window.requestAnimationFrame(tick);
    };

    tick();

    const handleResize = () => {
      sizes.width = window.innerWidth;
      sizes.height = window.innerHeight;
      camera.aspect = sizes.width / sizes.height;
      camera.updateProjectionMatrix();
      renderer.setSize(sizes.width, sizes.height);
      renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return <div className="wai-canvas">
    {/* @ts-ignore */}
    <canvas ref={canvasRef}></canvas>
  </div>;
};
