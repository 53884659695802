import React from "react";

import { HashRouter, Routes, Route } from "react-router-dom";

import { PageClaim } from "../page-claim";
import { PageMates } from "../page-mates";
import { PageTasks } from "../page-tasks";
import { PageStaking } from "../page-staking";
import { PageDashboard } from "../page-dashboard";
import { PageBoosters } from "../page-boosters";
import { PageSelectedBlockchain } from "../page-selected-blockchain";
import { Leaderboard } from "../leaderboard";
import { Wallet } from "../wallet";
import { Wai } from "../wai";

export const ROUTES = {
  MAIN: {
    PATH: "/",
    COMPONENT: <PageClaim />,
  },
  MATES: {
    PATH: "/mates",
    COMPONENT: <PageMates />,
  },
  TASKS: {
    PATH: "/tasks",
    COMPONENT: <PageTasks />,
  },
  CHANGE_BLOCKCHAIN: {
    PATH: "/change-blockchain",
    COMPONENT: <PageSelectedBlockchain />,
  },
  LEADERBOARD: {
    PATH: "/Statistics",
    COMPONENT: <Leaderboard />,
  },
  WAI: {
    PATH: "/wai",
    COMPONENT: <Wai />,
  },
  WALLET: {
    PATH: "/wallet",
    COMPONENT: <Wallet />,
  },
  STAKING: {
    PATH: "/staking",
    COMPONENT: <PageStaking />,
  },
  DASHBOARD: {
    PATH: "/dashboard",
    COMPONENT: <PageDashboard />,
  },
  BOOSTERS: {
    PATH: "/boosters",
    COMPONENT: <PageBoosters />,
  },
  OTHER: {
    PATH: "*",
    COMPONENT: <PageClaim />,
  },
};

export const Routers: React.FC = () => {
  return (
    <HashRouter>
      <Routes>
        <Route path={ROUTES.MAIN.PATH} element={ROUTES.MAIN.COMPONENT} />
        <Route path={ROUTES.MATES.PATH} element={ROUTES.MATES.COMPONENT} />
        <Route path={ROUTES.TASKS.PATH} element={ROUTES.TASKS.COMPONENT} />
        <Route path={ROUTES.STAKING.PATH} element={ROUTES.STAKING.COMPONENT} />
        <Route
          path={ROUTES.BOOSTERS.PATH}
          element={ROUTES.BOOSTERS.COMPONENT}
        />
        <Route
          path={ROUTES.DASHBOARD.PATH}
          element={ROUTES.DASHBOARD.COMPONENT}
        />
        <Route
          path={ROUTES.CHANGE_BLOCKCHAIN.PATH}
          element={ROUTES.CHANGE_BLOCKCHAIN.COMPONENT}
        />
        <Route
          path={ROUTES.LEADERBOARD.PATH}
          element={ROUTES.LEADERBOARD.COMPONENT}
        />
        <Route path={ROUTES.WALLET.PATH} element={ROUTES.WALLET.COMPONENT} />
        <Route path={ROUTES.WAI.PATH} element={ROUTES.WAI.COMPONENT} />
        <Route path={ROUTES.OTHER.PATH} element={ROUTES.OTHER.COMPONENT} />
      </Routes>
    </HashRouter>
  );
};
