import React from "react";

import "../index.scss";
import { ContentWrapper } from "../content-wrapper";

const roadMapActiveIcon = require("../img/road-map-active-icon.png") as string;
const roadMapDisabledIcon =
  require("../img/road-map-disabled-icon.png") as string;

const ITEMS = [
  {
    name: "Game launched",
    isActive: true,
  },
  {
    name: "Growing community",
    isActive: true,
  },
  {
    name: "Blockchain Selection",
    isActive: true,
  },
  {
    name: "$WCOIN airdrop",
    isActive: false,
  },
  {
    name: "Exchange listing",
    isActive: false,
  },
  {
    name: "MiniApp rebuilding",
    isActive: false,
  },
];

interface IProps {
  bg: string;
}

export const Roadmap: React.FC<IProps> = ({ bg }) => {
  return (
    <ContentWrapper title="Roadmap"  bg={ bg}>
      <div
        className="wallet-roadmap-wrapper"
      >
        {ITEMS.map((item) => (
          <div className="wallet-roadmap-item" key={item.name}>
            <img
              width="24px"
              height="24px"
              src={item.isActive ? roadMapActiveIcon : roadMapDisabledIcon}
              alt=""
            />
            <p className="white-color wallet-roadmap-text">{item.name}</p>
          </div>
        ))}
      </div>
    </ContentWrapper>
  );
};
