import React from "react";

import { CSSTransition } from "react-transition-group";

import "./index.scss";
import { Carousel } from "./carousel";

interface IProps {
  show: boolean;
  onClose: () => void;
}

export const OnboardingModal: React.FC<IProps> = ({ show, onClose }) => {
  return (
    <CSSTransition in={show} timeout={500} classNames="fade" unmountOnExit>
      <div className="overlay app-modal-root">
        <div
          className={`app-modal-content-wrapper task-modal-wrapper wai-onboarding-modal`}
          onClick={(e) => e.stopPropagation()}
          style={{
            height: "100%",
          }}
        >
          <div className="app-modal-content task-modal-content">
          <Carousel onClose={onClose} />
          </div>
        </div>
      </div>
    </CSSTransition>
  );
};
