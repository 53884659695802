import React from "react";

interface IProps {
  path: string;
  icon: string;
  label: string;
  onClick?: () => void;
  activeTaskCount?: number;
  width?: number;
  height?: number;
}

export const Links: React.FC<IProps> = ({
  path,
  icon,
  label,
  onClick,
  activeTaskCount,
  width, 
  height
}) => (
  <a
    onClick={() => onClick?.()}
    href={!!onClick ? undefined : path}
    style={{ position: "relative" }}
  >
    {!!activeTaskCount && <div className="active-task">{activeTaskCount}</div>}

    <img width={width} height={height} src={icon} />
    <span className="green-color">{label}</span>
  </a>
);
