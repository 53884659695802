import React from 'react';
import { TAppUser, TClaimState } from '../../contexts/types';

const cross = require("../img/cross.png") as string;


interface IProps {
    isOpen: boolean;
    onClose: () => void;
    onOpenSupport: () => void;
}

export const Collaboration:React.FC<IProps> = ({isOpen, onClose, onOpenSupport}) => {
    return <div
    className={`task-collaboration-notification ${
        isOpen ? "task-collaboration-notification-open" : ""
    }`}
  >
    <div className="task-collaboration-wrapper blockchain-content-wrapper ">
      <p
        className="task-collaboration-close"
        onClick={onClose}
      >
        <img src={cross} alt="" />
      </p>
      <p>🤝 Interested in collaboration?</p>
      <button
        className={`app-modal-content-button page-blockchain-btn button-wrapper `}
      >
        <div className="support-wrapper " onClick={onOpenSupport}>
          <b>Fill the form</b>
        </div>
      </button>
    </div>
  </div>
}