import React from "react";

import "./index.scss";
import { ZoomInAnimation } from "../../../../../page-animation";

interface IProps extends React.PropsWithChildren {
  title: string;
  subtitle?: string;
  label?: string;
}

export const CarouselTemplatePage: React.FC<IProps> = ({
  title,
  subtitle,
  label,
  children,
}) => {
  return (
    <div className="carousel-template-page">
      <ZoomInAnimation>
        <div className="carousel-template-page-text">
          <p className="carousel-template-page-text-title">{title}</p>
          {subtitle && (
            <p className="carousel-template-page-text-subtitle ab">
              {subtitle}
            </p>
          )}
          {label && (
            <p className="carousel-template-page-text-label ab">{label}</p>
          )}
        </div>
        <div className="carousel-template-page-children">{children}</div>
      </ZoomInAnimation>
    </div>
  );
};
