export enum EBooster {
  FULL_BATTERY = "FULL_BATTERY",
  LUCKY_DICE = "LUCKY_DICE",
  TAP = "TAP",
  CHARGER = "CHARGER",
  MAXIMIZER = "MAXIMIZER",
  MULTIPLIER = "MULTIPLIER",
  WORKER = "WORKER",
}

export type TBoosterUpgrades = Record<
  number,
  { level: number; price: number; upgrade: number }
>;
export type TMultiplierUpgrades= { price: number };
