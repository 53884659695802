import React from "react";

import { CarouselTemplatePage } from "../carousel-template-page";

import "./index.scss";

const img1 = require("./img/wai-currency.svg").default as string;

export const Slide3: React.FC = () => {
  return (
    <CarouselTemplatePage title="New Currency" subtitle="WAI">
      <div className="slides-wrapper">
        <div>
        <img width="187px" height="196.95px" src={img1} alt="" />

        </div>
          <div className="slides-text-wrapper">
            <div className="slides3-desc-wrapper-root-wrapper">
              <p className="slides-desc slides-desc-title">What is WAI?</p>
              <p className="slides-desc">
                WAI is a new in-game currency earned through the W-AI Trade Bot
                in W-Coin. It represents your passive income generated by
                automated cryptocurrency trading.
              </p>
            </div>
            <div className="slides3-desc-wrapper-root-wrapper">
              <p className="slides-desc slides-desc-title">How to earn WAI?</p>
              <p className="slides-desc">
                Start trading every 8 hours by clicking "Trade". Improve your
                bot’s characteristics to increase your WAI earnings per hour.
                Invite new experts to unlock advanced levels and features.
              </p>
            </div>
          </div>
      </div>
    </CarouselTemplatePage>
  );
};
