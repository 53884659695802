import React from "react";

import "../index.scss";
import { ContentWrapper } from "../content-wrapper";
import { formatStatisticsValue } from "../../../../utils";
import { useGetWaiQuery } from "../../../feature/users";

const safeIcon = require("../img/safe.png") as string;
const tonIcon = require("../img/ton-icon.png") as string;
const wIcon = require("../img/wcoin.png") as string;
const waiIcon = require("../img/wai.png") as string;
const soonIcon = require("../../img/soon.svg").default as string;

interface IProps {
  balance?: number;
  bg: string;
}

export const WalletBalance: React.FC<IProps> = ({ balance, bg }) => {
  const { data } = useGetWaiQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });

  return (
    <ContentWrapper title="Your balance" bg={bg}>
      <div className="wallet-balance">
        <img width="100px" src={safeIcon} alt="" />
        <div className="wallet-balance-main-wrapper">
          <div>
            <div className="wallet-balance-item-wrapper">
              <p className="wallet-roadmap-text green-color">
                Toncoin <img src={soonIcon} />
              </p>
              <div className="wallet-balance-item-text">
                <img width="18px" height="18px" src={tonIcon} alt="" />
                <p className="wallet-balance-item-ton-balance">0.00</p>
              </div>
            </div>
          </div>
          <div>
            <div className="wallet-balance-item-wrapper">
              <p className="wallet-roadmap-text green-color">W-Coin</p>
              <div className="wallet-balance-item-text">
                <img width="18px" height="18px" src={wIcon} alt="" />
                <p className="wallet-balance-item-ton-balance">
                  {formatStatisticsValue(balance)}
                </p>
              </div>
            </div>
          </div>
          <div>
            <div className="wallet-balance-item-wrapper">
              <p className="wallet-roadmap-text green-color">WAI</p>
              <div className="wallet-balance-item-text">
                <img width="18px" height="18px" src={waiIcon} alt="" />
                <p className="wallet-balance-item-ton-balance">
                  {data?.data?.balance || "0.00"}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ContentWrapper>
  );
};
