export type TWSkinsItems = {
    icon: string;
    title: string;
    price: number;
    selected: boolean;
    purchased: boolean;
    type: ESKins;
};

export enum ESKins  {
  RETRO = 'retro',
  DEFAULT = 'default',
  SHURIKEN = 'shuriken',
  CATIZEN = 'catizen',
  TON = 'ton',
  SOLANA = 'solana',
  DOT = 'dot',
  ETHEREUM  = 'ethereum',
  TRUST = 'trust',
  RUNE = 'rune',
  FREE_DUROV = 'free__durov',
  CATS = 'cats'
}