import React from "react";

import "./index.scss";
import { EBooster } from "../../types";
import { SmallCoin } from "../../../../page-claim/icons/small-coin";
import { BALANCE_COIN_SIZE_SMALL } from "../../../../../consts";
import { formatValues } from "../../../../../utils";
import { ZoomInAnimation } from "../../../../page-animation";

interface IProps {
  icon?: any;
  title: string;
  price: number;
  level?: number;
  onClick: () => void;
  isMaxLevel: boolean;
  type: EBooster;
  imageIcon?: string;
}

export const MainBoostersItem: React.FC<IProps> = ({
  icon,
  title,
  price,
  level,
  onClick,
  isMaxLevel,
  type,
  imageIcon,
}) => {
  const getLabel = () => {
    if (EBooster.MULTIPLIER === type) {
      return "You own it";
    } else if (EBooster.WORKER === type) {
      return "W-orking On you, Mate";
    }
    return "You reach the max level";
  };

  return (
    <div
      className={`main-boosters-root ${isMaxLevel ? "btn-disabled" : ""}`}
      onClick={onClick}
    >
      <div className="main-boosters-left">
        <div className="main-boosters-avatar blur-bg-strong">
          <ZoomInAnimation>
            {imageIcon ? <img src={imageIcon} width="54px" height="54px" /> : icon}
          </ZoomInAnimation>
        </div>

        <div className="main-boosters-description-wrapper ">
          <p className="green-color">{title}</p>
          <div className="main-boosters-description">
            {isMaxLevel ? (
              <p className="main-boosters-level green-color">{getLabel()}</p>
            ) : (
              <>
                <SmallCoin
                  width={BALANCE_COIN_SIZE_SMALL}
                  height={BALANCE_COIN_SIZE_SMALL}
                />
                <p className="main-boosters-price common-color">
                  {formatValues(price)}
                </p>
                {level && (
                  <>
                    <p className="main-boosters-dot gold-color">•</p>
                    <p className="main-boosters-level gold-color">
                      {level} lvl
                    </p>
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
