export enum ERune {
    START = 'START',
    C = 'C',
    O = 'O',
    I = 'I',
    N = "N",
    DONE = "DONE"
  };

  export type TRune = {
    step: ERune,
    completed: boolean;
  }

  export type TStepModalContent = {
    title: string;
    message: string;
    button: string;
    icon?: string;
} | null;