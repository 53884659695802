import React, { useState } from "react";

import "./index.scss";

import { Slide1 } from "./slides/slide1";
import { Slide2 } from "./slides/slide2";
import { Slide3 } from "./slides/slide3";
import { Slide4 } from "./slides/slide4";
import { Slide5 } from "./slides/slide5";

const closeOnboardingIcon = require("./img/ok.png") as string;
const nextArrowIcon = require("./img/next-arrow.png") as string;


interface IProps {
  onClose: () => void;
}

export const Carousel:React.FC<IProps> = ({onClose}) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const slides = [<Slide1 />, <Slide2 />, <Slide3 />, <Slide4 />, <Slide5 />];

  const handlePrevClick = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? slides.length - 1 : prevIndex - 1
    );
  };

  const handleNextClick = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === slides.length - 1 ? 0 : prevIndex + 1
    );
  };

  const handleCloseOnboarding = () => {
    onClose();
  };

  return (
    <div className="carousel">
      {currentIndex !== 0 && (
        <button className="carousel-button prev" onClick={handlePrevClick}>
        <img width="15px" height="30px" src={nextArrowIcon} alt="" style={{rotate: '200grad'}} />
        </button>
      )}
      <div className="carousel-slide">{slides[currentIndex]}</div>
      {currentIndex === 4 ? (
        <button className={`carousel-button next ${'carousel-button-next-paddings'}`} onClick={handleCloseOnboarding}>
         <img width="40px" src={closeOnboardingIcon} alt="" />
        </button>
      ) : (
        <button className="carousel-button next" onClick={handleNextClick}>
          <img width="15px" height="30px" src={nextArrowIcon} alt="" />
        </button>
      )}
    </div>
  );
};
