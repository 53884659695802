import React, { useContext } from "react";

import { ITask, useCollaborationNotification } from "./hooks";
import { ROUTES } from "../routers";

import "./index.scss";

import { Template } from "../template";
import { TaskModal } from "../app-modal/task-modal";
import { BackButton } from "@vkruglikov/react-telegram-web-app";
import { UserContext } from "../contexts/user-context";
import { TaskList } from "./task-list";
import { Collaboration } from "./collobaration";
import { TaskCounterAndDescription } from "./task-counter-and-description";
import { ToggleGroup } from "./toggle-group";
import { ETab } from "./type";
import { VerifyModal } from "./verify-modal";

const bg = require("../page-mates/img/mates-bg.jpg") as string;
const collabBg = require("./img/collab-img.jpg") as string;

export const PageTasks: React.FC = () => {
  const {
    user,
    task: {
      tasks,
      isLoading,
      isFetching,
      currentTask,
      onClaimTask,
      onChangeCurrentIndexTask,
      WALLET_TASK,
      THREE_FRIENDS_TASK,
      readyToClaim,
      isJoinedToTelegram,
      onTelegramGroupVerify,
    },
    onSetRuneStep,
  } = useContext(UserContext);

  const { openCollaboration, onCloseCollaboration, onOpenSupport } =
    useCollaborationNotification();

  const [activeTab, setActiveTab] = React.useState<ETab>(ETab.ACTIVE);

  function categorizeTasks(tasks: ITask[], isDone: boolean = false) {
    return tasks.reduce((acc, task) => {
      if (task.is_done !== isDone) {
        return acc;
      }

      const category = task.category;
      if (!acc[category]) {
        acc[category] = [];
      }
      acc[category].push(task);
      return acc;
    }, {} as { [key: string]: ITask[] });
  }
  const activeTasksList = categorizeTasks(tasks, false);

  const completedTasksList = categorizeTasks(tasks, true);

  const isCompletedTasksListEmpty = Object.keys(completedTasksList).every(
    (item) => completedTasksList[item]?.length === 0
  );

  const isCollab = activeTab === ETab.COLLABORATION;

  const [showVerifyModal, setShowVerifyModal] = React.useState<boolean>(false);

  const handleSetShowVerifyModal = (value: boolean) => {
    setShowVerifyModal(value);
  };

  return (
    <Template
      isLoading={isLoading}
      isFetching={isFetching}
      bg={isCollab ? collabBg : bg}
      isTasks={true}
    >
      <BackButton onClick={() => (window.location.hash = ROUTES.MAIN.PATH)} />
      <Collaboration
        isOpen={openCollaboration}
        onClose={onCloseCollaboration}
        onOpenSupport={onOpenSupport}
      />
      <div className="page page-1 page-tasks main-wrapper">
        <VerifyModal
          showVerifyModal={showVerifyModal}
          currentTask={currentTask ?? null}
          onClose={() => {
            onChangeCurrentIndexTask(null);
            handleSetShowVerifyModal(false);
          }}
          onClaim={() => onClaimTask(currentTask!.type)}
        />
        <TaskModal
          onTelegramGroupVerify={onTelegramGroupVerify}
          currentTask={currentTask?.doubleVerify ? null: currentTask}
          onClose={() => onChangeCurrentIndexTask(null)}
          onClaim={() => {
            if (currentTask?.index !== null) {
              onClaimTask(currentTask!.type);
            }
          }}
          isJoinedToTelegram={isJoinedToTelegram}
        />
        <div className="content mates-content task-content">
          <TaskCounterAndDescription user={user} isCollab={isCollab} />
          <div className="task-root-wrapper">
            <ToggleGroup activeTab={activeTab} onChange={setActiveTab} />
            {activeTab !== ETab.COLLABORATION && (
              <div className="task-wrapper">
                <div className="task-list-main-wrapper bebebe">
                  {/* <CollaborationButton onOpen={onOpenCollaboration}  /> */}
                  <div className="task-list-wrapper">
                    {activeTab === ETab.ACTIVE && (
                      <TaskList
                        walletTask={WALLET_TASK}
                        threeFriendsTask={THREE_FRIENDS_TASK}
                        taskList={activeTasksList}
                        onClaimTask={onClaimTask}
                        onChangeCurrentIndexTask={onChangeCurrentIndexTask}
                        readyToClaim={readyToClaim}
                        onSetShowVerifyModal={handleSetShowVerifyModal}
                      />
                    )}
                    {activeTab === ETab.COMPLETED && (
                      <TaskList
                        walletTask={WALLET_TASK}
                        threeFriendsTask={THREE_FRIENDS_TASK}
                        taskList={completedTasksList}
                        onClaimTask={onClaimTask}
                        onChangeCurrentIndexTask={onChangeCurrentIndexTask}
                        readyToClaim={readyToClaim}
                        isEmpty={isCompletedTasksListEmpty}
                        user={user}
                        onSetRuneStep={onSetRuneStep}
                        isCompleted={activeTab === ETab.COMPLETED}
                      />
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </Template>
  );
};
