import React from "react";
import "./index.scss";
import { RightAnimation } from "../../../page-animation";

const onboardingIcon = require("./img/onboarding-icon.png") as string;
const bgSwitchersOn = require("./img/bg-switcher-on.png") as string;
const bgSwitchersOff = require("./img/bg-switcher-off.png") as string;

interface IProps {
  onOnboardingModalOpen: () => void;
  showDynamicBg: boolean;
  onDynamicBgToggle: () => void;
}

export const Header: React.FC<IProps> = ({
  onOnboardingModalOpen,
  showDynamicBg,
  onDynamicBgToggle,
}) => {
  return (
    <div
      className="wai-header-root"
      style={{ padding: 0, alignItems: "baseline", marginBottom: "10px" }}
    >
      <p className="green-color ab" style={{ fontSize: "22px" }}>
        W-AI
      </p>
      <RightAnimation>
        <div className="icons page-claim-icons">
          <img
            onClick={onDynamicBgToggle}
            src={showDynamicBg ? bgSwitchersOn : bgSwitchersOff}
            alt=""
            width="35px"
            height="26px"
          />
          <img
            onClick={onOnboardingModalOpen}
            src={onboardingIcon}
            alt=""
            width="22px !important"
          />
        </div>
      </RightAnimation>
    </div>
  );
};
