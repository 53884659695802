import React from 'react';

import { useWizard } from 'react-use-wizard';

import './index.scss';
import { PERCENT_TO_DAYS } from '..';
import { TStaking } from '../../contexts/types';

const bg = require("./img/placeholder-1.png") as string;

const stakingOption1 = require('./img/staking-option-1.svg').default as string;
const stakingOption2 = require('./img/staking-option-2.svg').default as string;
const stakingOption3 = require('./img/staking-option-3.svg').default as string;

interface IProps {
  onChangeStacking: (value: Partial<TStaking>) => void;
  selectedValue?: number;
}

export const StakingStep2: React.FC<IProps> = ({onChangeStacking, selectedValue}) => {

  const { nextStep } = useWizard();

  const handleSetDays = (days: number ) => {
    onChangeStacking({days: days,percent: PERCENT_TO_DAYS[days] });
  }

  return (
    <>
      <div className="form-placeholder" style={{ backgroundImage: `url(${bg})`, backgroundRepeat: 'no-repeat' }}>
        <div className="title staking-title ab">Select the duration </div>
        <div className="subtitle">The amount you about to stake will be locked until the term ends</div>
        <div className="radio-group">
          <div className={`custom-radio-button ${selectedValue === 3 ? 'selected' : ''}`} onClick={() => handleSetDays(3)}>
            <img src={stakingOption1} alt="" />
          </div>
          <div className={`custom-radio-button ${selectedValue === 7 ? 'selected' : ''}`} onClick={() => handleSetDays(7)}>
            <img src={stakingOption2} alt="" />
          </div>
          <div className={`custom-radio-button ${selectedValue === 14 ? 'selected' : ''}`} onClick={() => handleSetDays(14)}>
            <img src={stakingOption3} alt="" />
          </div>
        </div>
      </div>
      <div className={`form-buttons ${selectedValue ? '' : 'btn-disabled'}`}>
        <button className="main-button step1-actions-btn ab" onClick={() => nextStep()}>Continue</button>
      </div>
    </>
  );
};