import React from "react";
import { useWebApp } from "@vkruglikov/react-telegram-web-app";
import { TAppUser, TClaimState } from "../contexts/types";
import { useUpdateUser } from "../../hooks";
import { TELEGRAM_LINK } from "../../consts";

export const useOpenTelegramLink = () => {
  const webApp = useWebApp();

  const handleOpenTelegramLink = (link?: string) => {
    webApp.openTelegramLink( link ? `https://t.me/${link}` : TELEGRAM_LINK);
  };

  return {
    onOpenTelegramLink: handleOpenTelegramLink,
  };
};

export const useClickDebounce = (user: TAppUser, setClaimState: React.Dispatch<React.SetStateAction<TClaimState | undefined>>) => {
  const claimState = user?.claim_state;

  const [updateUser] = useUpdateUser();

  const [debouncedValue, setDebouncedValue] = React.useState<
    TClaimState | undefined
  >(claimState);
  const timerRef = React.useRef<any>();

  // Update balance with debounce
  React.useEffect(() => {
    try{
      if (user.database_user?.id) {
        clearTimeout(timerRef.current);
  
        timerRef.current = setTimeout(() => {
          handleUpdateClient();
        }, 500);
      }
  
      return () => {
        clearTimeout(timerRef.current);
      };
    } catch(e) {
      console.warn(e);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedValue]);

  function handleUpdateClient() {
    try {
      if (user.database_user?.id) {
        const newData = debouncedValue;
  
        // @ts-ignore
        setClaimState?.((prevState) => ({...(prevState || {}), ...newData}));
        // @ts-ignore
        updateUser({id: user.database_user.id, ...newData})
          .then(() => {
            return;
          })
          .catch((e: any) => {
            console.warn(e);
          });
      }
    } catch(e) {
      console.warn(e);
    }
  }

  return {
    setDebouncedValue,
  }
};
