import React from 'react';

export const BoostResetTimer = () => {
    const [time, setTime] = React.useState('');

    function formatTime(diff: number) {
        const hours = Math.floor(diff / 3600000);
        const minutes = Math.floor((diff % 3600000) / 60000);
        const seconds = Math.floor((diff % 60000) / 1000);
      
        const formattedHours = hours < 10 ? `0${hours}` : `${hours}`;
        const formattedMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;
        const formattedSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`;
      
        return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
      }
  
    React.useEffect(() => {
      const timer = setInterval(() => {
        const now = new Date();
        const nextDay = new Date(Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate() + 1));
        const diff = nextDay.getTime() - now.getTime();
        setTime(formatTime(diff));
      }, 1000);
  
      return () => clearInterval(timer);
    }, []);
  
    return (
      <>{ time ? `${(time)}` : ''}</>
    );
  }