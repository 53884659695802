import React from "react";
import { CSSTransition } from "react-transition-group";
import { ILeague } from "../../../feature/users";
import { TLeagueData } from "../../models";
import "./index.scss";
import { LeagueListContent } from "./league-list-content";
import { TLeague } from "../../../feature/users/wai.models";

interface IProps {
  show: boolean;
  league?: ILeague;
  onClose: () => void;
  onGetWaiLeaderboardByLeague: (league: TLeague) => void;
  isLoading: boolean;
  leagueData: TLeagueData;
  currentModalLeague: TLeague | null;
}

export const LeagueListModal: React.FC<IProps> = ({
  show,
  league,
  onClose,
  leagueData,
  isLoading,
  onGetWaiLeaderboardByLeague,
}) => {
  const closeComponent = () => (
    <div className="close-modal ab" onClick={onClose}>
      x
    </div>
  );
  const content = () => {
    return (
        <LeagueListContent
          league={league}
          leagueData={leagueData}
          onGetWaiLeaderboardByLeague={onGetWaiLeaderboardByLeague}
          isLoading={isLoading}
        />
      );
  };

  return (
    <CSSTransition in={show} timeout={500} classNames="fade" unmountOnExit>
      {league && leagueData[league.name]?.name ? (
        <div className="overlay app-modal-root wai-modal-padding blur">
          <div
            className={`app-modal-content-wrapper task-modal-wrapper league-modal-wrapper`}
            onClick={(e) => e.stopPropagation()}
            style={{ height: "100%", }}
          >
            {closeComponent()}
            <div className={`app-modal-content task-modal-content ${isLoading && 'pointer-events-none'}`} style={{height: '100%'}}>
              {content()}
            </div>
          </div>
        </div>
      ) : null}
    </CSSTransition>
  );
};
