import React from "react";
import { Template } from "../template";

import "./index.scss";
import { usePageSelectedBlockchainApi } from "./hooks";
import { BackButton } from "@vkruglikov/react-telegram-web-app";
import { ROUTES } from "../routers";
import { Support } from "./support";
import { BottomAnimation, ZoomInAnimation } from "../page-animation";
const bg = require("./img/bg.png") as string;

const logo = require("./img/w-logo.png") as string;
const logoText = require("./img/w-coin-text.png") as string;
const ton = require("./img/ton_symbol.svg").default as string;

export const PageSelectedBlockchain: React.FC = () => {
  const {
    isLoading,
    userName,
    onOpenSupport,
  } = usePageSelectedBlockchainApi();
  
  return (
    <Template isLoading={isLoading} bg={bg} customBg={bg}>
      <BackButton onClick={() => (window.location.hash = ROUTES.MAIN.PATH)} />
      <div className="page page-1 page-tasks main-wrapper blockchain-wrapper">
        <div className="blockchain-content-wrapper" style={{ flex: 1 }}>
          <p className="blockchain-content-name ab">Hello, {userName}</p>
          <ZoomInAnimation>
          <div className="blockchain-content-select-wrapper-main">
            <img width="218px" src={logo} alt="" />
            <div className="blockchain-ton-text-wrapper">
              <img width="136px" src={logoText} alt="" />
              <p className="blockchain-ton-text blockchain-ton-text-first">Powered by $TON               <img width="23px" src={ton} alt="" />
              </p>
              <p className="blockchain-ton-text">Boosted by Mates 🧑🏼‍🚀</p>
            </div>
          </div>
          </ZoomInAnimation>
        </div>
        <div style={{ width: "100%", borderRadius: '27px' }}>
          <BottomAnimation>
            <div className="blockchain-content-wrapper blockchain-content-footer">
              <button
                className={`app-modal-content-button page-blockchain-btn button-wrapper `}
              >
                <div className="support-wrapper" onClick={onOpenSupport}>
                  <Support />
                  <b>Support</b>
                </div>
              </button>
              <p className="blockchain-rights">© 2024 W-Coin. All rights reserved.</p>
            </div>
          </BottomAnimation>
        </div>
      </div>
    </Template>
  );
};
